import React, { Component } from 'react';
import { permissionContext } from '../infrastructure/context/permissionContext';
import { Collapse, NavbarToggler } from 'reactstrap';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';

export class SideBar extends Component {

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {


        return (
            <permissionContext.Consumer>
                {({ permissions }) => {

                    return (<div>

                        <NavbarToggler className="d-block d-md-none bg-dark sidebar" onClick={this.toggleNavbar}>
                            <span style={{ color: "white" }}>View menu</span>
                        </NavbarToggler>

                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar style={{ width: "100%", minWidth: "200px" }}>

                            <nav className="sidebar pcoded-navbar menu-light" style={{ paddingTop: "10px", zIndex: "0" }}>
                                <div className="sidebar-sticky" style={{ width: "100%", minWidth: "200px" }}>
                                    <ul className="nav flex-column pcoded-inner-navbar">

                                        {permissions.includes("form.definitions.view.all") && <>
                                            <Route path="/form-definitions" >
                                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                    <Link className="nav-link" to="/form-definitions">
                                                        <span className="pcoded-micon"><i className="feather icon-list"></i></span>
                                                        <span className="pcoded-mtext">Form definitions</span>
                                                    </Link>
                                                </li>}
                                            </Route>
                                            <Route path="/form-types" >
                                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                    <Link className="nav-link" to="/form-types">
                                                        <span className="pcoded-micon"><i className="feather icon-grid"></i></span>
                                                        <span className="pcoded-mtext">Form types</span>
                                                    </Link>
                                                </li>}
                                            </Route>
                                        </>}
                                        {permissions.includes("users.view.all") && <>
                                            <Route path="/users" >
                                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                    <Link className="nav-link" to="/users">
                                                        <span className="pcoded-micon"><i className="feather icon-user"></i></span>
                                                        <span className="pcoded-mtext">Users</span>
                                                    </Link>
                                                </li>}
                                            </Route>
                                        </>}
                                        {permissions.includes("invitations.view.all") && <>
                                            <Route path="/invitations" >
                                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                    <Link className="nav-link" to="/invitations">
                                                        <span className="pcoded-micon"><i className="feather icon-user-plus"></i></span>
                                                        <span className="pcoded-mtext">Invitations</span>
                                                    </Link>
                                                </li>}
                                            </Route>
                                        </>}


                                        {(permissions.includes("roles.view.all") || permissions.includes("api.clients.modify")) &&
                                            <li className="nav-item pcoded-menu-caption">
                                                <label>Administration</label>
                                            </li>
                                        }

                                        {permissions.includes("roles.view.all") && <>
                                            <Route path="/roles" >
                                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                    <Link className="nav-link" to="/roles">
                                                        <span className="pcoded-micon"><i className="feather icon-file-text"></i></span>
                                                        <span className="pcoded-mtext">Roles</span>
                                                    </Link>
                                                </li>}
                                            </Route>
                                        </>}

                                        {permissions.includes("api.clients.view.all") && <>
                                            <Route path="/api-clients" >
                                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                    <Link className="nav-link" to="/api-clients">
                                                        <span className="pcoded-micon"><i className="feather icon-zap"></i></span>
                                                        <span className="pcoded-mtext">API Clients</span>
                                                    </Link>
                                                </li>}
                                            </Route>
                                        </>}

                                    </ul>
                                </div>
                            </nav>

                        </Collapse>

                    </div>);
                }}
            </permissionContext.Consumer>
        );
    }
}
