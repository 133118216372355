import React, { Component } from 'react';
import { Layout } from '../../shared/Layout';
import { ApiClientsTable } from './ApiClientsTable';

export class ApiClients extends Component {

    render() {
        return (
            <Layout>
                <ApiClientsTable />
            </Layout>
        );
    }

}
