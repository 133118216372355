import { ListQueryParams, ApiResponse } from "../ApiClient";
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from "../Helper";

export class ApiClientQueryParams extends ListQueryParams {

    WithShowSuspended(value: any) {
        if (!value)
            this.WithParam("status", "active");
        return this;
    }

    WithRoleId(value: any) {
        this.WithParam("roleId", value);
        return this;
    }

    WithStatus(value: any) {
        this.WithParam("status", value);
        return this;
    }

}

export class ApiClientsClient {

    static async List(params: ApiClientQueryParams) {

        var query = (await GetAPIDomain()) + "/api-clients" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/api-clients/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/api-clients/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));

    }

    static async Create(name: string, roles: string[]) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/api-clients/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "roleIds": roles
            })
        }));
    }

    static async Update(id: string, name: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/api-clients/" + id, {
            method: 'PATCH',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            }])
        }));
    }

    static async SetRoles(id: string, roleIds: string[]) {

        var query = (await GetAPIDomain()) + "/api-clients/" + id + "/set-roles";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post',
            body: JSON.stringify({
                "roleIds": roleIds ? roleIds : []
            })
        }));
    }

    static async SetRole(id: string, roleId: string) {

        return await ApiClientsClient.SetRoles(id, roleId ? [roleId] : []);
    }

    static async Suspend(id: string) {

        var query = (await GetAPIDomain()) + "/api-clients/" + id + "/suspend";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post'
        }));
    }

    static async Restore(id: string) {

        var query = (await GetAPIDomain()) + "/api-clients/" + id + "/restore";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post'
        }));
    }

}