import React, { Component } from 'react';

export class MinLayout extends Component {
    static displayName = MinLayout.name;

    render() {
        return (
            <div className="auth-wrapper bg-light" style={{paddingTop:"50px"}}>
                {this.props.children}
            </div>
        );
    }
  }
