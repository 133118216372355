import React, { Component } from 'react';
import { Layout } from '../../shared/Layout';
import { FormTypesTable } from './FormTypesTable';

export class FormTypes extends Component {

    render() {
        return (
            <Layout>
                <FormTypesTable />
            </Layout>
        );
    }

}
