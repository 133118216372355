import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Spinner, Card, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { ApiClient } from '../infrastructure/ApiClient';
import { permissionContext } from '../infrastructure/context/permissionContext';
import { Layout } from '../shared/Layout';

export class SignIn extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true };
    }


    componentDidMount() {
        if (ApiClient.IsAuthenticated())
            this.setState({
                next: "home"
            });
    }


    renderContent() {

        if (this.state.next === "home") {
            return (<Redirect to="/" />);
        } else {
            return (<permissionContext.Consumer>
                {({ refresh }) => {

                    return (<Formik
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        onSubmit={async (fields, { setErrors, setFieldError }) => {
                            if (await ApiClient.SignIn(fields.email, fields.password, error => {

                                if (error === "too_many_attempts") {
                                    setFieldError("email", "Too many attempts, please wait 5 minutes before trying again");
                                } else if (error === "account_locked") {
                                    setFieldError("email", "Your account is locked, please contact concierge to unlock");
                                } else {
                                    setFieldError("email", "Invalid email or password");
                                }

                            })) {
                                refresh();
                            }
                        }}
                        render={({ errors, status, touched, isSubmitting }) => (
                            <Form>
                                <div className="form-group">
                                    <Field name="email" placeholder="Email" type="text" className={'bgWhite form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                    <ErrorMessage name="email" component="div" className="invalid-feedback text-left" />
                                </div>
                                <div className="form-group">
                                    <Field name="password" placeholder="Password" type="password" className={'bgWhite form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                    <ErrorMessage name="password" component="div" className="invalid-feedback text-left" />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting} style={{ width: "100%" }}>
                                        {!isSubmitting && "SIGN IN"}
                                        {isSubmitting && <Spinner animation="border" />}
                                    </button>
                                </div>

                                <div className="form-group text-right">
                                    <Link className="text-right" to="/password-reset/begin">Forgotten password?</Link>
                                </div>
                            </Form>
                        )}
                    />);
                }}
            </permissionContext.Consumer>);
        }
    }

    render() {

        return (
            <Layout>
            <Card style={{ maxWidth: "600px", width: "100%" }}>
                <CardBody>
                    <div>
                        <div>
                            <div className="card-body text-left">

                                <h3 className="mb-4">Sign in</h3>
                                <hr />
                                {this.renderContent()}

                                <hr />

                                <h4>Don't have an account?</h4>
                                <p>Please let your manager know you need access</p>
                            </div>
                        </div>
                    </div>
                    </CardBody>
                </Card></Layout>
        );

    }
}