import React, { Component } from 'react';
import { Container, Spinner, Alert, Card, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import { ApiClient } from '../../infrastructure/ApiClient';
import { Layout } from '../../shared/Layout';

export class PasswordResetComplete extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true, success: false };
    }

    componentDidMount() {
        if (ApiClient.IsAuthenticated()) {
            ApiClient.LogOut();
        }
    }

    renderContent() {

        return (<Formik
            initialValues={{
                password: ''
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {
                var response = await ApiClient.CompletePasswordReset(this.props.match.params.code, fields.password);

                if (!response.successful) {

                    response.validationErrors.map(error => {

                        setFieldError("password", error.message);

                        if (error.key === "code")
                            this.setState({ badCode: true });

                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ success: true });
                }
            }}
            render={({ errors, status, touched, isSubmitting }) => (
                <Form>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <Field name="password" type="password" className={'form-control' + (errors.password ? ' is-invalid' : '')} />
                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting} style={{ width: "100%" }}>
                            {!isSubmitting && "RESET PASSWORD"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}
        />);
    }

    renderSuccessScreen() {

        return (<div><Alert color="success">
            <h5>Success</h5>
            We have reset your password, please use your new password from now on
                    </Alert>
            <Link className="btn mr-2 btn-outline-dark mt-2" to="/sign-in">SIGN IN</Link></div>);
    }

    render() {

        return (
            <Layout>
            <Card style={{ maxWidth: "600px", width: "100%" }}>
                <CardBody>
                    <div>
                        <Container>
                            <h3 className="mb-4">Choose your new password</h3>
                            <hr />
                            {!this.state.badCode && !this.state.success && this.renderContent()}
                            {!this.state.badCode && this.state.success && this.renderSuccessScreen()}
                            {this.state.badCode && <div><Alert color="danger">
                                <h5>Invalid code</h5>
                        The password reset link is no longer valid
                    </Alert>
                                <Link className="btn mr-2 btn-outline-dark mt-2" to="/password-reset/begin">START THE PROCESS AGAIN</Link></div>}
                        </Container>
                    </div>

                    </CardBody>
                </Card></Layout>
        );

    }
}
