import { ListQueryParams, ApiResponse } from "../ApiClient";
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from "../Helper";

export class FormTypeQueryParams extends ListQueryParams {

}

export class FormTypesClient {

    static async List(params: FormTypeQueryParams) {

        var query = (await GetAPIDomain()) + "/form-types" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/form-types/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/form-types/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));

    }

    static async Create(name: string, description: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/form-types/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "description": description
            })
        }));
    }

    static async Update(id: string, name: string, description: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/form-types/" + id, {
            method: 'PATCH',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            },
            {
                "op": "replace",
                "path": "description",
                "value": description
            }])
        }));
    }
}