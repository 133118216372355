import { ListQueryParams, ApiResponse } from "../ApiClient";
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from "../Helper";

export class FormDefinitionQueryParams extends ListQueryParams {

    WithTypeId(value: any) {
        this.WithParam("typeId", value);
        return this;
    }

    WithVisibility(value: any) {
        this.WithParam("visibility", value);
        return this;
    }

    WithShowArchived(value: any) {
        this.WithParam("includeArchived", value);
        return this;
    }

}

export class FormDefinitionsClient {

    static async List(params: FormDefinitionQueryParams) {

        var query = (await GetAPIDomain()) + "/form-definitions" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/form-definitions/" + id, {
            headers: await GetAPIHeaders()
        }));

    }


    static async GetPublished(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/form-definitions/" + id+"/published", {
            headers: await GetAPIHeaders()
        }));

    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/form-definitions/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));

    }

    static async Create(name: string, description: string, typeId: string, versionId: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/form-definitions/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "description": description,
                "typeId": typeId,
                "visibility": "draft",
                "definition": "{}",
                "baseVersionId": versionId
            })
        }));
    }

    static async Update(id: string, name: string, description: string, typeId: string, visibility: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/form-definitions/" + id, {
            method: 'PATCH',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            },
                {
                    "op": "replace",
                    "path": "description",
                    "value": description
                },
                {
                    "op": "replace",
                    "path": "typeId",
                    "value": typeId
                },
                {
                    "op": "replace",
                    "path": "visibility",
                    "value": visibility
                }])
        }));
    }

    static async UpdateDefinition(id: string, definition: any, published: Boolean, versionId: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/form-definitions/" + id + "/definition", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                definition: definition,
                publish: published,
                versionId: versionId
            })
        }));
    }

}