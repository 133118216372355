import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Badge, Spinner, Card, CardHeader, Row, Col, CardBody, ListGroup, ListGroupItem, Collapse, Button, CardFooter, Alert, FormGroup, Label, Input } from 'reactstrap';
import { FormDefinitionsClient } from '../../infrastructure/api/FormDefinitionsClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip';
import { faAlignJustify, faAngleRight, faBuilding, faCheckCircle, faChevronCircleDown, faChevronCircleRight, faStickyNote, faChevronDown, faChevronUp, faList, faPlus, faTrash, faStamp, faStar, faEye } from '@fortawesome/free-solid-svg-icons'
import EasyEdit from 'react-easy-edit';
import confirm from "reactstrap-confirm";
import { SectionTypes } from '../../infrastructure/constants/SectionTypes';
import { FormPreviewsClient } from '../../infrastructure/api/FormPreviewsClient';
import { GetPulseDomain } from '../../infrastructure/Helper';
import { VersionSelector } from '../../infrastructure/selectors/VersionSelector';
import { FormVersionsClient } from '../../infrastructure/api/FormVersionsClient';
import { Layout } from '../../shared/Layout';

export class FormDefinitionsEditDefinition extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: true, versionId: "", saveLoading: false, unsavedChanges: false, versionError: false, publishLoading: false, authenticated: true, next: false, name: "", addQuestionType: "", expandAllState: "collapsed", versionValue: null };
        this.logicType = "always";
        this.saveform = this.saveform.bind(this);
    }

    hasUnsavedChanges() {
        this.setState({
            unsavedChanges: true
        });
    }

    zeroPad(num, places) {
        var zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
    }

    getTitleForQuestionId(id) {
        if (this.state.definition && this.state.definition.fields && this.state.definition.fields.length > 0) {
            for (var i = 0; i < this.state.definition.fields.length; i++) {

                if (this.state.definition.fields[i].id === id) {
                    if (this.state.definition.fields[i].title)
                        return this.state.definition.fields[i].title;
                    return this.state.definition.fields[i].content;
                }

            }
        }
        return "Unknown";
    }

    async addQuestion() {
        var sectionType = "statement";

        if (await confirm({
            title: "Question type",
            message: (<form>
                <div className="form-group">
                    <label htmlFor="sectionType">What type of section to add</label>
                    <select name="visibility"
                        onChange={(value) => sectionType = value.target.value}
                        className={'form-control'}>
                        <option value="statement" label="Statement" />
                        <option value="buttonOptions" label="Button options" />
                        <option value="longText" label="Long text" />
                        <option value="likert" label="Likert" />
                        <option value="rating" label="NPS" />
                        <option value="site" label="Site" />
                    </select>
                </div>
            </form>),
            confirmText: "Add section",
            cancelText: "Cancel",
        })) {

            var questionNumber = 1;
            while (this.getTitleForQuestionId("Q "+this.zeroPad(questionNumber, 3)) !== "Unknown") {
                questionNumber = questionNumber + 1;
                if (questionNumber > 10000)
                    break;
            }

            var questionId = "Q " + this.zeroPad(questionNumber, 3);

            if (sectionType === SectionTypes.STATEMENT) {
                this.state.definition.fields.push({
                    id: questionId,
                    content: "Enter statement content",
                    type: sectionType,
                    settings: {
                        endFlow: false
                    },
                    nextButton: {
                        text: "Next"
                    }
                });
            } else if (sectionType === SectionTypes.BUTTON_OPTIONS) {
                this.state.definition.fields.push({
                    id: questionId,
                    title: "Enter question",
                    type: sectionType,
                    settings: {
                        multiSelect: false
                    },
                    nextButton: {
                        text: "Next"
                    }
                });
            } else if (sectionType === SectionTypes.SITE) {
                this.state.definition.fields.push({
                    id: questionId,
                    title: "Which site are you at?",
                    type: sectionType,
                    settings: {
                        multiSelect: false,
                        includeHome: false
                    }
                });
            } else if (sectionType === SectionTypes.LONG_TEXT) {
                this.state.definition.fields.push({
                    id: questionId,
                    title: "Enter question",
                    type: sectionType,
                    settings: {
                        endFlow: false
                    },
                    nextButton: {
                        text: "Next"
                    }
                });
            } else if (sectionType === SectionTypes.LIKERT) {
                this.state.definition.fields.push({
                    id: questionId,
                    title: "Enter question",
                    type: sectionType,
                    options: [
                        {
                            displayText: "Strongly Disagree",
                            value: "0"
                        },
                        {
                            displayText: "Disagree",
                            value: "1"
                        },
                        {
                            displayText: "Neither Agree or Disagree",
                            value: "2"
                        },
                        {
                            displayText: "Agree",
                            value: "3"
                        },
                        {
                            displayText: "Strongly Agree",
                            value: "4"
                        }
                    ],
                    settings: {
                        endFlow: false
                    },
                    nextButton: {
                        text: "Next"
                    }
                });
            } else if (sectionType === SectionTypes.RATING) {
                this.state.definition.fields.push({
                    id: questionId,
                    title: "Enter question",
                    type: sectionType,
                    settings: {
                        endFlow: false,
                        shape: "number",
                        steps: "11",
                        start: "Not likely",
                        end: "Extremely likely"
                    },
                    options: [
                        {
                            displayText: "0",
                            value: "0"
                        },
                        {
                            displayText: "1",
                            value: "1"
                        },
                        {
                            displayText: "2",
                            value: "2"
                        },
                        {
                            displayText: "3",
                            value: "3"
                        },
                        {
                            displayText: "4",
                            value: "4"
                        },
                        {
                            displayText: "5",
                            value: "5"
                        },
                        {
                            displayText: "6",
                            value: "6"
                        },
                        {
                            displayText: "7",
                            value: "7"
                        },
                        {
                            displayText: "8",
                            value: "8"
                        },
                        {
                            displayText: "9",
                            value: "9"
                        },
                        {
                            displayText: "10",
                            value: "10"
                        }
                    ],
                    nextButton: {
                        text: "Next"
                    }
                });
            }

            this.setState({ definition: this.state.definition });
            this.hasUnsavedChanges();
        }
    }

    pad(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }

    updateExpandAllState() {

        if (!this.state.definition)
            return;

        if (this.state.definition.fields.every(e => e.isOpen === true)) {
            console.log("expanded");
            this.setState({
                expandAllState: "expanded"
            });
            return;
        }

        if (this.state.definition.fields.every(e => !e.isOpen === false)) {
            console.log("collapsed");
            this.setState({
                expandAllState: "collapsed"
            });
            return;
        }

        if (this.state.definition.fields.some(e => e.isOpen === true) && this.state.definition.fields.some(e => e.isOpen === false)) {
            console.log("mixed");
            this.setState({
                expandAllState: "mixed"
            });
            return;
        }

        console.log("default");
        this.setState({
            expandAllState: "collapsed"
        });
    }

    componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    validateLogic(logic) {

        var missingDestinationErrors = logic
            .filter(item => item.destination === null || item.destination.length === 0 || item.destination === "choose destination")
            .map(item => "Logic item for question '" + this.getTitleForQuestionId(item.source) + "' does not have a destination set");

        var invalidDestinationErrors = logic
            .filter(item => (item.destination !== null && item.destination.length > 0 && item.destination !== "choose destination") && this.getTitleForQuestionId(item.destination) === "Unknown")
            .map(item => "Logic item for question '" + this.getTitleForQuestionId(item.source) + "' does not have a valid destination set");

        var invalidValueChosen = logic
            .filter(item => item.condition.op === "equal" && (item.condition.value === null || item.condition.value.length === 0 || item.condition.value === "choose value"))
            .map(item => "Logic item for question '" + this.getTitleForQuestionId(item.source) + "' does not have a valid value set");

        return missingDestinationErrors.concat(invalidDestinationErrors).concat(invalidValueChosen);
    }

    validateFields(fields) {

        var missingStatement = fields
            .filter(item => item.type === "statement" && (item.content === null || item.content.length === 0 || item.content ==="Enter statement content"))
            .map(item => "Statement '" + item.id + "' does not have any content set");

        var missingTitle = fields
            .filter(item => item.type !== "statement" && (item.title === null || item.title.length === 0 || item.title === "Enter question"))
            .map(item => "Question '" + item.id + "' does not have a title set");

        return missingStatement.concat(missingTitle);

    }

    async createPreview() {

        this.setState({
            isCreatingPreview: true
        });

        var preview = await FormPreviewsClient.CreatePreview(this.props.match.params.id, this.state.definition);
        
        var url = "/form-definitions/preview/" + preview.data.id;

        window.open(url, "Survey preview", 'height=768,width=1366,left=10,top=10,titlebar=no,toolbar=no,menubar=no,location=no,directories=no,status=no');

        this.setState({
            isCreatingPreview: false
        });
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/form-definitions/details/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <Layout>
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update form definition
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update details about the form</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                    <Button color="default" style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2 btn-default" onClick={async () => {
                                        await this.createPreview();
                                        }} disabled={this.state.isCreatingPreview}>
                                            {this.state.isCreatingPreview && <Spinner animation="border" />}
                                            {!this.state.isCreatingPreview && <span><FontAwesomeIcon icon={faEye} /> Preview</span>}
                                    </Button>
                                    </div>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Button color="default" style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2 btn-default" onClick={async () => {
                                            if (!this.state.unsavedChanges || await confirm({
                                                title: "There are unsaved changes",
                                                message: <p>Are you sure you want to cancel?</p>,
                                                confirmText: "Leave, discard changes",
                                                cancelText: "Stay",
                                            })) {
                                                window.location = "/form-definitions/details/" + this.props.match.params.id;
                                            }
                                        }}> Cancel</Button>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                        <CardHeader>
                            <VersionSelector value={this.state.versionValue} label="Base form version" placeholder="No previous versions" initialId={this.state.versionId} formId={this.props.match.params.id}
                                onChange={async (name, value) => {

                                    this.setState({
                                        versionValue: value
                                    });

                                    if (value == null)
                                        return;
                                    if (value.value !== this.state.versionId) {

                                        this.setState({
                                            loading: true
                                        });

                                        var response = await FormVersionsClient.Get(value.value);

                                        this.setState({
                                            loading: false
                                        });

                                        if (response.data.definition) {

                                            if (!response.data.definition.fields)
                                                response.data.definition.fields = [];

                                            response.data.definition.fields.map(item => item.isOpen = false);

                                            this.setState({ definition: response.data.definition, loading: false }, () => {
                                                ReactTooltip.rebuild();
                                            });
                                        }

                                    }

                                    this.setState({
                                        versionId: value.value,
                                        unsavedChanges: false
                                    });

                                }}
                            />
                        </CardHeader>
                        {this.state.errors && this.state.errors.length > 0 && <CardHeader>
                            <Alert color="danger">
                                <ul style={{margin:"0"}}>
                                    {this.state.errors.map(e => <li>{e}</li>)}
                                </ul>
                            </Alert>
                        </CardHeader>}
                        <CardBody>
                    <Row>

                        <Col>

                                    {this.state.definition && <div>

                                    <Row>
                                        <Col style={{ marginLeft: 20 }}>
                                            <Button className="btn btn-light float-left" onClick={() => {
                                                if (this.state.expandAllState === "expanded") {
                                                    this.state.definition.fields.map(item => item.isOpen = false);
                                                    this.setState({
                                                        definition: this.state.definition
                                                    });
                                                    this.updateExpandAllState();
                                                } else {
                                                    this.state.definition.fields.map(item => item.isOpen = true);
                                                    this.setState({
                                                        definition: this.state.definition
                                                    });
                                                    this.updateExpandAllState();
                                                }
                                            }}>
                                                {this.state.expandAllState === "collapsed" && <FontAwesomeIcon icon={faChevronCircleRight} />}
                                                {this.state.expandAllState === "mixed" && <FontAwesomeIcon icon={faChevronDown} />}
                                                {this.state.expandAllState === "expanded" && <FontAwesomeIcon icon={faChevronCircleDown} />}
                                            </Button>
                                            <h6 className="pt-2">Form logic</h6>
                                        </Col>
                                        <Col className="col-auto">
                                                <Button style={{ marginTop: "5px" }} className="btn btn-success" onClick={() => this.addQuestion()}>
                                                <FontAwesomeIcon icon={faPlus} /> Add Question
                                            </Button>
                                        </Col>
                                    </Row>

                                    <hr />

                                        {this.state.definition.fields && this.state.definition.fields.length === 0 && <h3 className="text-center">Add form logic using the green button above</h3>}

                                    {this.state.definition.fields && this.state.definition.fields.map(item =>
                                        <Card className="bg-light" id={item.id}>
                                            <CardHeader>
                                                <Row>
                                                    <Col style={{ paddingTop: "10px" }}>

                                                        <Button className="btn btn-light float-left" onClick={() => {
                                                            item.isOpen = !item.isOpen;
                                                            this.setState({ definition: this.state.definition }, () => this.updateExpandAllState());

                                                        }}>
                                                            {item.isOpen && <FontAwesomeIcon icon={faChevronCircleDown} />}
                                                            {!item.isOpen && <FontAwesomeIcon icon={faChevronCircleRight} />}
                                                        </Button>
                                                        <div className="pt-2">
                                                            <h6 className="header-title m-0 mb-0">
                                                                <Badge color="primary" className="mr-2">{item.id}</Badge>
                                                                {item.type === SectionTypes.STATEMENT && <><FontAwesomeIcon icon={faAlignJustify} /> Statement</>}
                                                                {item.type === SectionTypes.BUTTON_OPTIONS && <><FontAwesomeIcon icon={faList} /> Buttons</>}
                                                                {item.type === SectionTypes.SITE && <><FontAwesomeIcon icon={faBuilding} /> Site select</>}
                                                                {item.type === SectionTypes.LONG_TEXT && <><FontAwesomeIcon icon={faStickyNote} /> Long text</>}
                                                                {item.type === SectionTypes.LIKERT && <><FontAwesomeIcon icon={faStamp} /> Likert</>}
                                                                {item.type === SectionTypes.RATING && <><FontAwesomeIcon icon={faStar} /> NPS</>}
                                                            </h6>
                                                        </div>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        {this.state.definition.fields.indexOf(item) === 0 && <Badge className="mt-3" color="success">Quiz starts here</Badge>}
                                                    </Col>
                                                    <Col className="text-right">
                                                        <div className="pt-2">
                                                            <h6>
                                                                <small className="text-muted">{item.title && item.title}{!item.title && "Statement"} &#183; <i>{item.id}</i></small>


                                                                {this.state.definition.fields.indexOf(item) > 0 && <Button id={"up-" + item.id} className="btn btn-light ml-2" onClick={() => {

                                                                    var oldOffsetTop = document.getElementById(item.id).offsetTop;
                                                                    document.getElementById("up-" + item.id).blur();

                                                                    var index = this.state.definition.fields.indexOf(item);
                                                                    this.state.definition.fields.splice(index - 1, 0, this.state.definition.fields.splice(index, 1)[0]);
                                                                    this.setState({ definition: this.state.definition }, () => {
                                                                        //var newOffsetTop = document.getElementById(item.id).offsetTop;
                                                                        //window.scrollBy(0, newOffsetTop - oldOffsetTop);
                                                                    });

                                                                    this.hasUnsavedChanges();
                                                                }}>
                                                                    <FontAwesomeIcon icon={faChevronUp} />
                                                                </Button>}

                                                                {this.state.definition.fields.indexOf(item) < (this.state.definition.fields.length - 1) && <Button id={"down-" + item.id} className="btn btn-light" onClick={() => {

                                                                    var oldOffsetTop = document.getElementById(item.id).offsetTop;
                                                                    document.getElementById("down-" + item.id).blur();
                                                                    var index = this.state.definition.fields.indexOf(item);
                                                                    this.state.definition.fields.splice(index + 1, 0, this.state.definition.fields.splice(index, 1)[0]);
                                                                    this.setState({ definition: this.state.definition }, () => {
                                                                        //var newOffsetTop = document.getElementById(item.id).offsetTop;
                                                                        //window.scrollBy(0, newOffsetTop - oldOffsetTop);
                                                                    });

                                                                    this.hasUnsavedChanges();
                                                                }}>
                                                                    <FontAwesomeIcon icon={faChevronDown} />
                                                                </Button>}

                                                            </h6>
                                                        </div>


                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <Collapse isOpen={item.isOpen}>
                                                <CardBody>
                                                    <Row>
                                                        <Col>
                                                            {item.type === SectionTypes.STATEMENT && <ListGroup>
                                                                <p><strong>Content</strong></p>
                                                                <ListGroupItem className="text-left">

                                                                    <EasyEdit type="textarea" value={item.content}
                                                                        attributes={{ className: "form-control", rows: "5" }}
                                                                        saveOnBlur
                                                                        saveButtonLabel="Ok"
                                                                        onSave={(value) => {
                                                                            item.content = value;
                                                                            this.setState({ definition: this.state.definition });
                                                                            this.hasUnsavedChanges();
                                                                        }} />
                                                                </ListGroupItem>
                                                            </ListGroup>}

                                                            {(item.type === SectionTypes.RATING || item.type === SectionTypes.SITE || item.type === SectionTypes.BUTTON_OPTIONS || item.type === SectionTypes.LONG_TEXT || item.type === SectionTypes.LIKERT) && <ListGroup>
                                                                <p><strong>Question</strong></p>
                                                                <ListGroupItem className="text-left">

                                                                    <EasyEdit type="text" value={item.title}
                                                                        attributes={{ className: "form-control" }}
                                                                        saveButtonLabel="Ok"
                                                                        saveOnBlur
                                                                        onSave={(value) => {
                                                                            item.title = value;
                                                                            this.setState({ definition: this.state.definition });
                                                                            this.hasUnsavedChanges();
                                                                        }} />

                                                                </ListGroupItem>
                                                            </ListGroup>}

                                                            {(item.type === SectionTypes.BUTTON_OPTIONS || item.type === SectionTypes.LIKERT) && <ListGroup>

                                                                <Row><Col>
                                                                    <p><strong>Options</strong></p></Col>
                                                                    {item.type === SectionTypes.BUTTON_OPTIONS && <Col className="col-auto">
                                                                        <Button style={{ marginTop: "5px" }} className="btn btn-success btn-sml" onClick={async () => {

                                                                            var optionDisplay = "";
                                                                            var optionValue = "";

                                                                            if (await confirm({
                                                                                title: "Question type",
                                                                                message: (<form>
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="display">Display</label>
                                                                                        <input name="display" placeholder="Option One" type="text" className={'bgWhite form-control'}
                                                                                            value={this.optionDisplay}
                                                                                            onChange={(value) => optionDisplay = value.target.value}
                                                                                            onBlur={(value) => optionDisplay = value.target.value}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="value">Value</label>
                                                                                        <input name="value" placeholder="option-one" type="text" className={'bgWhite form-control'}
                                                                                            value={this.optionValue}
                                                                                            onChange={(value) => optionValue = value.target.value}
                                                                                            onBlur={(value) => optionValue = value.target.value}
                                                                                        />
                                                                                    </div>
                                                                                </form>),
                                                                                confirmText: "Add option",
                                                                                cancelText: "Cancel",
                                                                            })) {

                                                                                if (!item.options)
                                                                                    item.options = [];

                                                                                item.options.push({
                                                                                    displayText: optionDisplay,
                                                                                    value: optionValue
                                                                                });
                                                                                this.setState({ definition: this.state.definition });
                                                                                this.hasUnsavedChanges();
                                                                            }

                                                                        }}>
                                                                            <FontAwesomeIcon icon={faPlus} />
                                                                        </Button>
                                                                    </Col>}
                                                                </Row>

                                                                {!(item.options && item.options.length > 0) && <ListGroupItem className="text-left">
                                                                    <i>No options configured</i>
                                                                </ListGroupItem>}
                                                                {item.options && item.options.length > 0 && item.options.map(buttonOption =>
                                                                    <Row><Col><ListGroupItem className="text-left">
                                                                        <Row>
                                                                            <Col>
                                                                                <EasyEdit type="text" value={buttonOption.displayText}
                                                                                    attributes={{ className: "form-control" }}
                                                                                    saveButtonLabel="Ok"
                                                                                    saveOnBlur
                                                                                    onSave={(value) => {
                                                                                        buttonOption.displayText = value;
                                                                                        this.setState({ definition: this.state.definition });
                                                                                        this.hasUnsavedChanges();
                                                                                    }} />
                                                                            </Col>
                                                                            <Col className="text-right">
                                                                                <small className="text-muted">{buttonOption.value}</small>
                                                                            </Col>
                                                                        </Row>
                                                                    </ListGroupItem></Col>
                                                                        {item.type === SectionTypes.BUTTON_OPTIONS && <Col className="col-auto">
                                                                            <Button style={{ marginTop: "5px" }} className="btn btn-danger" onClick={() => {

                                                                                var indexToRemove = item.options.indexOf(buttonOption);
                                                                                item.options.splice(indexToRemove, 1);

                                                                                this.setState({ definition: this.state.definition });
                                                                                this.hasUnsavedChanges();
                                                                            }}>
                                                                                <FontAwesomeIcon icon={faTrash} />
                                                                            </Button>
                                                                        </Col>}</Row>)}
                                                            </ListGroup>}

                                                            {item.type === SectionTypes.SITE && <ListGroup>
                                                                <p><strong>Settings</strong></p>
                                                                <ListGroupItem className="text-left">Working from home is

                                                                        <div className="inline-wrapper">
                                                                        <EasyEdit
                                                                            type="select"
                                                                            value={item.settings.includeHome === true ? 'true' : 'false'}
                                                                            saveButtonLabel="Ok"
                                                                            saveOnBlur
                                                                            attributes={{
                                                                                className: "form-control ml-2"
                                                                            }}
                                                                            displayComponent={<>
                                                                                {item.settings.includeHome === true && <strong> an option</strong>}{item.settings.includeHome === false && <strong> not an option</strong>}
                                                                            </>}
                                                                            options={[
                                                                                { label: 'an option', value: 'true' },
                                                                                { label: 'not an option', value: 'false' }]}
                                                                            onSave={(value) => {
                                                                                item.settings.includeHome = (value === 'true');
                                                                                this.setState({ definition: this.state.definition });
                                                                                this.hasUnsavedChanges();
                                                                            }}
                                                                        /></div>
                                                                </ListGroupItem>
                                                            </ListGroup>}

                                                            {(item.type === SectionTypes.RATING || item.type === SectionTypes.STATEMENT || item.type === SectionTypes.LONG_TEXT || item.type === SectionTypes.LIKERT) && <ListGroup>
                                                                <p><strong>Settings</strong></p>
                                                                <ListGroupItem className="text-left">The statement
                                                                    <div className="inline-wrapper">
                                                                        <EasyEdit
                                                                            type="select"
                                                                            value={item.settings.endFlow === true ? 'true' : 'false'}
                                                                            saveOnBlur
                                                                            saveButtonLabel="Ok"
                                                                            attributes={{
                                                                                className: "form-control ml-2"
                                                                            }}
                                                                            displayComponent={<>
                                                                                {item.settings.endFlow === true && <strong> ends the quiz</strong>}{item.settings.endFlow === false && <strong> does not end the quiz</strong>}
                                                                            </>}
                                                                            options={[
                                                                                { label: 'ends the quiz', value: 'true' },
                                                                                { label: 'does not end the quiz', value: 'false' }]}
                                                                            onSave={(value) => {
                                                                                item.settings.endFlow = (value === 'true');
                                                                                this.setState({ definition: this.state.definition });
                                                                                this.hasUnsavedChanges();
                                                                            }}
                                                                        /></div>
                                                                </ListGroupItem>
                                                                <ListGroupItem className="text-left">Action button label:
                                                                    <div className="inline-wrapper">
                                                                        <EasyEdit type="text" value={item.nextButton.text}
                                                                            attributes={{ className: "form-control" }}
                                                                            saveButtonLabel="Ok"
                                                                            saveOnBlur
                                                                            displayComponent={<strong> {item.nextButton.text}</strong>}
                                                                            onSave={(value) => {
                                                                                item.nextButton.text = value;
                                                                                this.setState({ definition: this.state.definition });
                                                                                this.hasUnsavedChanges();
                                                                            }} />
                                                                    </div>
                                                                </ListGroupItem>
                                                                {item.type === SectionTypes.RATING && <ListGroupItem className="text-left">Start label:
                                                                    <div className="inline-wrapper">
                                                                        <EasyEdit type="text" value={item.settings.start}
                                                                            attributes={{ className: "form-control" }}
                                                                            saveButtonLabel="Ok"
                                                                            saveOnBlur
                                                                            displayComponent={<strong> {item.settings.start}</strong>}
                                                                            onSave={(value) => {
                                                                                item.settings.start = value;
                                                                                this.setState({ definition: this.state.definition });
                                                                                this.hasUnsavedChanges();
                                                                            }} />
                                                                    </div>
                                                                </ListGroupItem>}
                                                                {item.type === SectionTypes.RATING && <ListGroupItem className="text-left">End label:
                                                                    <div className="inline-wrapper">
                                                                        <EasyEdit type="text" value={item.settings.end}
                                                                            attributes={{ className: "form-control" }}
                                                                            saveButtonLabel="Ok"
                                                                            saveOnBlur
                                                                            displayComponent={<strong> {item.settings.end}</strong>}
                                                                            onSave={(value) => {
                                                                                item.settings.end = value;
                                                                                this.setState({ definition: this.state.definition });
                                                                                this.hasUnsavedChanges();
                                                                            }} />
                                                                    </div>
                                                                </ListGroupItem>}
                                                            </ListGroup>}


                                                            <ListGroup>
                                                                <Button style={{ width: "150px" }} className="btn btn-danger" onClick={async () => {


                                                                    var result = await confirm({
                                                                        title: "Are you sure?",
                                                                        message: "This will remove this section from the quiz and any related logic",
                                                                        confirmText: "Yes - delete",
                                                                        cancelText: "No",
                                                                    });

                                                                    if (!result)
                                                                        return;

                                                                    var indexToRemove = this.state.definition.fields.indexOf(item);
                                                                    this.state.definition.fields.splice(indexToRemove, 1);

                                                                    var itemsToRemove = [];
                                                                    for (var i = 0; i < this.state.definition.logic.length; i++) {

                                                                        if (this.state.definition.logic[i].destination === item.id || this.state.definition.logic[i].source === item.id) {
                                                                            itemsToRemove.push(this.state.definition.logic[i]);
                                                                        }

                                                                    }

                                                                    for (var j = 0; j < itemsToRemove.length; j++) {
                                                                        var indexOfLogicToRemove = this.state.definition.logic.indexOf(itemsToRemove[j]);
                                                                        this.state.definition.logic.splice(indexOfLogicToRemove, 1);
                                                                    }

                                                                    this.setState({ definition: this.state.definition });
                                                                    this.hasUnsavedChanges();

                                                                }}>
                                                                    Delete item
                                                                        </Button>
                                                            </ListGroup>

                                                        </Col>
                                                        <Col>
                                                            <ListGroup>
                                                                <Row><Col>
                                                                    <p><strong>Logic</strong></p>
                                                                </Col>

                                                                    {!(item.settings && item.settings.endFlow === true) && <Col className="col-auto">
                                                                        <Button style={{ marginTop: "5px" }} className="btn btn-success btn-sml" onClick={async () => {

                                                                            var logicType = "always";

                                                                            if (item.type === SectionTypes.STATEMENT ||
                                                                                item.type === SectionTypes.SITE ||
                                                                                item.type === SectionTypes.LONG_TEXT ||
                                                                                await confirm({
                                                                                title: "Logic type",
                                                                                message: (<form>
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="logicType">What operation are we adding</label>
                                                                                        <select name="logicType"
                                                                                            onChange={(value) => {
                                                                                                logicType = value.target.value;
                                                                                            }}
                                                                                            className={'form-control'}>
                                                                                            <option value="equal" label="If answer is" />
                                                                                            <option value="always" label="Always do this action" />
                                                                                        </select>
                                                                                    </div>
                                                                                </form>),
                                                                                confirmText: "Add option",
                                                                                cancelText: "Cancel",
                                                                            })) {

                                                                                if (logicType === "always") {
                                                                                    this.state.definition.logic = this.state.definition.logic.filter(e => e.source !== item.id || (e.source === item.id && e.condition.op !== "always"));
                                                                                }

                                                                                this.state.definition.logic.push({
                                                                                    type: "field",
                                                                                    source: item.id,
                                                                                    destination: "choose destination",
                                                                                    condition: {
                                                                                        op: logicType,
                                                                                        value: "choose value"
                                                                                    }
                                                                                });


                                                                                this.setState({ definition: this.state.definition });
                                                                                this.hasUnsavedChanges();
                                                                            }

                                                                        }}>
                                                                            <FontAwesomeIcon icon={faPlus} />
                                                                        </Button>
                                                                    </Col>}

                                                                </Row>

                                                                {!(item.settings && item.settings.endFlow === true) && <>

                                                                    {(this.state.definition.logic && this.state.definition.logic.filter(l => l.source === item.id && l.condition.op !== "always").length > 0)
                                                                        && <>{this.state.definition.logic.filter(l => l.source === item.id && l.condition.op !== "always")
                                                                            .map(logicItem => <Row><Col><ListGroupItem className="text-left">

                                                                                {item.options && logicItem.condition && logicItem.condition.op === "equal" && <span>
                                                                                    <FontAwesomeIcon icon={faAngleRight} /> If answer is '<div className="inline-wrapper">
                                                                                        <EasyEdit
                                                                                            type="select"
                                                                                            saveOnBlur
                                                                                            value={logicItem.condition.value}
                                                                                            saveButtonLabel="Ok"
                                                                                            attributes={{
                                                                                                className: "form-control ml-2"
                                                                                            }}
                                                                                            displayComponent={<strong>{logicItem.condition.value}</strong>}
                                                                                            options={item.options.map(option => {
                                                                                                return {
                                                                                                    label: option.displayText,
                                                                                                    value: option.value
                                                                                                };
                                                                                            })}
                                                                                            onSave={(value) => {
                                                                                                logicItem.condition.value = value;
                                                                                                this.setState({ definition: this.state.definition });
                                                                                                this.hasUnsavedChanges();
                                                                                            }}
                                                                                        /></div>' then go to '<div className="inline-wrapper">
                                                                                        <EasyEdit
                                                                                            type="select"
                                                                                            saveOnBlur
                                                                                            value={logicItem.condition.value}
                                                                                            saveButtonLabel="Ok"
                                                                                            attributes={{
                                                                                                className: "form-control ml-2"
                                                                                            }}
                                                                                            displayComponent={<strong>{logicItem.destination}</strong>}
                                                                                            options={this.state.definition.fields.map(option => {
                                                                                                return {
                                                                                                    label: option.title || option.content,
                                                                                                    value: option.id
                                                                                                };
                                                                                            })}
                                                                                            onSave={(value) => {
                                                                                                logicItem.destination = value;
                                                                                                this.setState({ definition: this.state.definition });
                                                                                                this.hasUnsavedChanges();
                                                                                            }}
                                                                                        /></div>'
                                                                                </span>}

                                                                            </ListGroupItem></Col>
                                                                                <Col className="col-auto">
                                                                                    <Button style={{ marginTop: "5px" }} className="btn btn-danger" onClick={() => {

                                                                                        var indexToRemove = this.state.definition.logic.indexOf(logicItem);
                                                                                        this.state.definition.logic.splice(indexToRemove, 1);

                                                                                        this.setState({ definition: this.state.definition });
                                                                                        this.hasUnsavedChanges();

                                                                                    }}>
                                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>)}
                                                                        <p><strong>Otherwise</strong></p>
                                                                        </>}


                                                                    {!(this.state.definition.logic && this.state.definition.logic.filter(l => l.source === item.id && l.condition.op === "always").length > 0) &&
                                                                        <ListGroupItem className="text-left">
                                                                            <span><FontAwesomeIcon icon={faAngleRight} /> Proceed to the next item when answered</span>
                                                                        </ListGroupItem>
                                                                    }

                                                                    {(this.state.definition.logic && this.state.definition.logic.filter(l => l.source === item.id && l.condition.op === "always").length > 0)
                                                                        && <>{this.state.definition.logic.filter(l => l.source === item.id && l.condition.op === "always")
                                                                            .map(logicItem => <Row><Col><ListGroupItem className="text-left">

                                                                                {logicItem.condition && logicItem.condition.op === "always" && <span>
                                                                                    <FontAwesomeIcon icon={faAngleRight} /> Proceed to question '<div className="inline-wrapper">
                                                                                        <EasyEdit
                                                                                            type="select"
                                                                                            saveOnBlur
                                                                                            value={logicItem.condition.value}
                                                                                            saveButtonLabel="Ok"
                                                                                            attributes={{
                                                                                                className: "form-control ml-2"
                                                                                            }}
                                                                                            displayComponent={<strong>{logicItem.destination}</strong>}
                                                                                            options={this.state.definition.fields.map(option => {
                                                                                                return {
                                                                                                    label: option.title || option.content,
                                                                                                    value: option.id
                                                                                                };
                                                                                            })}
                                                                                            onSave={(value) => {
                                                                                                logicItem.destination = value;
                                                                                                this.setState({ definition: this.state.definition });
                                                                                                this.hasUnsavedChanges();
                                                                                            }}
                                                                                        /></div>' when answered
                                                                                </span>}

                                                                            </ListGroupItem></Col>
                                                                                <Col className="col-auto">
                                                                                    <Button style={{ marginTop: "5px" }} className="btn btn-danger" onClick={() => {

                                                                                        var indexToRemove = this.state.definition.logic.indexOf(logicItem);
                                                                                        this.state.definition.logic.splice(indexToRemove, 1);

                                                                                        this.setState({ definition: this.state.definition });
                                                                                        this.hasUnsavedChanges();

                                                                                    }}>
                                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>)}
                                                                        </>}

                                                                </>}

                                                                {item.settings && item.settings.endFlow === true && <ListGroupItem className="text-left">
                                                                    <span><FontAwesomeIcon icon={faCheckCircle} /> Submit completed form</span>
                                                                </ListGroupItem>}

                                                            </ListGroup>

                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Collapse >
                                        </Card>)}

                                    {!this.state.definition.fields && <div className="card-box">
                                        <Card className="bg-light">
                                            <CardBody>
                                                <h6>No form created, use the form builder to define the form</h6>
                                            </CardBody>
                                        </Card>
                                    </div>}

                            </div>}

                        </Col>
                    </Row>
                        </CardBody>
                        <CardFooter>
                            <Button style={{ marginTop: "5px", float: "left" }} className="btn btn-success" onClick={() => this.addQuestion()}>
                                <FontAwesomeIcon icon={faPlus} /> Add Question
                            </Button>
                        </CardFooter>
                        <CardFooter>
                            <h6>Versioning</h6>
                            <FormGroup>
                                <div className="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="versionOption" id="newVersion" value="newVersion" />
                                    <label class="form-check-label" for="newVersion">Create new version on save</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="versionOption" id="saveToExisting" value="saveToExisting" />
                                    <label class="form-check-label" for="saveToExisting">Update existing version</label>
                                </div>
                            </FormGroup>

                            {this.state.versionError && <Alert color="danger">
                                <ul style={{ margin: "0" }}>
                                    Please specify if you want to save as a new version or not
                                </ul>
                            </Alert>}

                            <h6>Publishing</h6>
                            <FormGroup>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="publish" id="publishVersion" />
                                    <label class="form-check-label" for="publishVersion">
                                        Publish changes automatically
                                    </label>
                                </div>
                            </FormGroup>

                            <hr />
                            <Link onClick={this.saveform} className="btn mr-2 btn-outline-dark mt-2 mb-2">
                                {!this.state.saveLoading && "Save"}
                                {this.state.saveLoading && <Spinner animation="border" />}
                            </Link>
                            <Button color="default"  className="btn mr-2 btn-outline-dark mt-2 btn-default" onClick={async () => {
                                if (!this.state.unsavedChanges || await confirm({
                                    title: "There are unsaved changes",
                                    message: <p>Are you sure you want to cancel?</p>,
                                    confirmText: "Leave, discard changes",
                                    cancelText: "Stay",
                                })) {
                                    window.location = "/form-definitions/details/" + this.props.match.params.id;
                                }
                            }}> Cancel</Button>
                        </CardFooter>

                    </Card>
                    </div>
                </Layout>
            );
        }
    }

    validateForm() {

        var errors = this.validateLogic(this.state.definition.logic).concat(this.validateFields(this.state.definition.fields));
        var versionError = document.querySelector('input[name="versionOption"]:checked') == null;

        this.setState({
            errors: errors,
            versionError: versionError
        });

        return errors.length === 0 && !versionError;
    }

    async saveform() {

        if (!this.validateForm()) {
            if (document.querySelector('input[name="versionOption"]:checked') != null)
                window.scrollTo(0, 0);
            return false;
        }

        this.setState({ saveLoading: true });

        this.state.definition.logic = this.state.definition.logic.filter(l => l.condition.op !== "always").concat(this.state.definition.logic.filter(l => l.condition.op === "always"));
        console.log(this.state.definition);

        this.setState({ definition: this.state.definition });

        var newVersion = document.querySelector('input[name="versionOption"]:checked').value === "newVersion";
        var response = await FormDefinitionsClient.UpdateDefinition(this.props.match.params.id, this.state.definition, document.getElementById("publishVersion").checked, newVersion ? null : this.state.versionId);

        if (!response.successful) {

            if (response.status === 404) {
                this.setState({ next: true });
            } else {
                this.setState({ saveLoading: false });
            }

        } else {
            this.setState({ next: true });
        }

        return false;
    }

    async populateData(id) {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await FormDefinitionsClient.GetPublished(id);

            if (!response.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false
                });
            } else {

                if (response.data.definition == null)
                    response = await FormDefinitionsClient.Get(id);

                this.setState({
                    loading: false,
                    versionId: response.data.versionId
                });

                if (response.data.definition) {

                    if (!response.data.definition.fields)
                        response.data.definition.fields = [];

                    response.data.definition.fields.map(item => item.isOpen = false);

                    this.setState({ definition: response.data.definition, loading: false }, () => {
                        ReactTooltip.rebuild();
                    });
                } else {
                    this.setState({
                        definition: {
                            logic: [],
                            fields: []
                        }
                    }, () => {
                        ReactTooltip.rebuild();
                    });
                }

            }
        }
    }
}
