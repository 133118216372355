import React, { Component } from 'react';
import { Layout } from '../../shared/Layout';
import { UsersTable } from './UsersTable';

export class Users extends Component {

    render() {
        return (<Layout>
            <UsersTable /></Layout>
        );
    }

}
