import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup, Badge } from 'reactstrap';
import Moment from 'react-moment';
import { TableHeader } from '../../infrastructure/TableHeader';
import { faCopy, faStar, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faStar as faoStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { permissionContext } from '../../infrastructure/context/permissionContext';
import { FormVersionQueryParams, FormVersionsClient } from '../../infrastructure/api/FormVersionsClient';
import ReactTooltip from 'react-tooltip';
import confirm from "reactstrap-confirm";

export class FormVersionsTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], isSubmitting: {}, isStarSubmitting: {}, isDeleteSubmitting: {}, isCloneSubmitting: {}, loading: false, total: 0, skip: 0, take: 50, formStatus: "", authenticated: true, search: "", sort: "created", direction: "desc" };

        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async clear() {
        this.setState({
            search: "",
            formStatus: "",
            skip: 0
        }, () => this.update());
    }

    componentDidMount() {
        this.populateData(this.state.skip, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    renderTable(data) {

        var columns = [
            { name: "", sort: "", static: true },
            { name: "Name", sort: "name" },
            { name: "Status", sort: "isPublished" },
            { name: "Created", sort: "created" },
            { name: "", sort: "", static: true }];

        return (<div className="table-responsive">
            <ReactTooltip />
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={columns}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />
                <tbody>
                    {data.map(item =>
                        <tr key={item.id}>
                            <td style={{ "width": 50 }}>

                                <Button color="default" style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2 btn-default" onClick={async () => {
                                    await this.star(item.id, !item.isStarred);
                                }} disabled={this.state.isStarSubmitting[item.id]}>
                                    {this.state.isStarSubmitting[item.id] && <Spinner animation="border" />}
                                    {!this.state.isStarSubmitting[item.id] && item.isStarred === true && <FontAwesomeIcon icon={faStar} />}
                                    {!this.state.isStarSubmitting[item.id] && item.isStarred === false && <FontAwesomeIcon icon={faoStar} />}
                                </Button>

                            </td>
                            <td style={{ verticalAlign: "middle" }} data-tip={item.note}>
                                {item.name && <span>{item.name}</span>}
                                {!item.name && <i>Not specified</i>}
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                                {item.isPublished === true && <Badge color="succes">Published</Badge>}
                                {item.isPublished === false && <Badge color="secondarys">Draft</Badge>}
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                                {item.created && <Moment utc local format="ddd DD MMM, h:mm a">{item.created}</Moment>}
                            </td>
                            <td>

                                {item.isPublished === false && <permissionContext.Consumer>
                                    {({ permissions }) => {
                                        return (permissions.includes("form.definitions.modify") && <Button color="default" style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2 btn-default" onClick={async () => {
                                            await this.delete(item.id);
                                        }} disabled={this.state.isDeleteSubmitting[item.id]}>
                                            {!this.state.isDeleteSubmitting[item.id] && <FontAwesomeIcon icon={faTrash} />}
                                            {this.state.isDeleteSubmitting[item.id] && <Spinner animation="border" />}
                                        </Button>);

                                    }}
                                </permissionContext.Consumer>}

                                <permissionContext.Consumer>
                                    {({ permissions }) => {
                                        return (permissions.includes("form.definitions.modify") && <Button color="default" style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2 btn-default" onClick={async () => {
                                            await this.clone(item.id);
                                        }} disabled={this.state.isSubmitting[item.id]}>
                                            {!this.state.isCloneSubmitting[item.id] && <FontAwesomeIcon icon={faCopy} />}
                                            {this.state.isCloneSubmitting[item.id] && <Spinner animation="border" />}
                                        </Button>);

                                    }}
                                </permissionContext.Consumer>

                                <permissionContext.Consumer>
                                    {({ permissions }) => {

                                        return (permissions.includes("form.definitions.modify") && <Link style={{ float: "right" }} to={"/form-versions/edit/" + item.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>);

                                    }}
                                </permissionContext.Consumer>

                                {item.isPublished === false && <permissionContext.Consumer>
                                    {({ permissions }) => {
                                        return (permissions.includes("form.definitions.modify") && <Button color="default" style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2 btn-default" onClick={async () => {
                                            await this.publish(item.id);
                                        }} disabled={this.state.isSubmitting[item.id]}>
                                            {!this.state.isSubmitting[item.id] && "Publish"}
                                            {this.state.isSubmitting[item.id] && <Spinner animation="border" />}
                                            </Button>);

                                    }}
                                </permissionContext.Consumer>}

                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="8"><h4 className="text-muted text-center mt-3"><i>No versions to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>
        );
    }

    render() {


        const {
            hideFilter,
            description
        } = this.props;


        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Form history ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">{description} {!description && "List of form versions"}</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                    </Card>
                    <Card>
                        {!hideFilter && <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        <InputGroup>
                                            <Input type="text" placeholder="Search" value={this.state.search} onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {(this.state.search) &&
                                                    <button onClick={() => { this.clear(); }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>}

                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async delete(id) {

        var result = await confirm({
            title: "Are you sure?",
            message: "This will remove this verson forever",
            confirmText: "Yes - delete",
            cancelText: "No",
        });

        if (!result)
            return;

        this.state.isDeleteSubmitting[id] = true;
        this.setState({
            isDeleteSubmitting: this.state.isDeleteSubmitting
        });
        await FormVersionsClient.Delete(id);
        await this.update();

        this.state.isDeleteSubmitting[id] = false;
        this.setState({
            isDeleteSubmitting: this.state.isDeleteSubmitting
        });
    }

    async publish(id) {

        this.state.isSubmitting[id] = true;
        this.setState({
            isSubmitting: this.state.isSubmitting
        });
        await FormVersionsClient.Publish(id);
        await this.update();

        this.state.isSubmitting[id] = false;
        this.setState({
            isSubmitting: this.state.isSubmitting
        });
    }

    async star(id, starred) {

        this.state.isStarSubmitting[id] = true;
        this.setState({
            isStarSubmitting: this.state.isStarSubmitting
        });
        await FormVersionsClient.Star(id, starred);
        await this.update();

        this.state.isStarSubmitting[id] = false;
        this.setState({
            isStarSubmitting: this.state.isStarSubmitting
        });
    }

    async clone(id) {

        this.state.isCloneSubmitting[id] = true;
        this.setState({
            isCloneSubmitting: this.state.isCloneSubmitting
        });
        await FormVersionsClient.Clone(id);
        await this.update();

        this.state.isCloneSubmitting[id] = false;
        this.setState({
            isCloneSubmitting: this.state.isCloneSubmitting
        });
    }

    async populateData(skip, take) {

        const {
            formId,
            versionChanged
        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var query = new FormVersionQueryParams();

            var response = await FormVersionsClient.List(query
                .Paginate(skip, take)
                .Search(this.state.search)
                .WithFormId(formId)
                .Sort(this.state.sort, this.state.direction));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total }, () => {
                    ReactTooltip.rebuild();
                });
            }

            if (versionChanged)
                versionChanged();
        }
    }
}
