import { ListQueryParams, ApiResponse } from "../ApiClient";
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from "../Helper";

export class RoleQueryParams extends ListQueryParams {

}

export class RolesClient {

    static async List(params: RoleQueryParams) {

        var query = (await GetAPIDomain()) + "/roles" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/roles/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/roles/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));

    }

    static async Create(name: string, description: string, permissions: string[]) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/roles/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "description": description,
                "permissions": permissions
            })
        }));
    }

    static async Update(id: string, name: string, description: string, permissions: string[]) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/roles/" + id, {
            method: 'PATCH',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            },
            {
                "op": "replace",
                "path": "description",
                "value": description
            },
            {
                "op": "replace",
                "path": "permissions",
                "value": permissions
            }])
        }));
    }
}