import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FormDefinitionsClient } from '../../infrastructure/api/FormDefinitionsClient';
import { TypeSelector } from '../../infrastructure/selectors/TypeSelector';
import { FormSelector } from '../../infrastructure/selectors/FormSelector';
import { VersionSelector } from '../../infrastructure/selectors/VersionSelector';
import { Layout } from '../../shared/Layout';

export class FormDefinitionsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, screen: "initial"
        };

    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        return (<Formik
            initialValues={{
                name: '',
                typeId: '',
                description: '',
                versionId: '',
                formId: ''
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.name) {
                    setFieldError("name", "Please specify the form name");
                    return;
                }

                if (!fields.description) {
                    setFieldError("description", "Please specify the form description");
                    return;
                }

                if (!fields.typeId) {
                    setFieldError("type", "Please specify the form type");
                    return;
                }

                var response = await FormDefinitionsClient.Create(fields.name, fields.description, fields.typeId.value, fields.versionId ? fields.versionId.value : null);

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="name">Name*</label>
                            <Field name="name" placeholder="E.g. Form 01" type="text" className={'bgWhite form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                            <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                        </div>
                    </Col>
                </Row>
                <Row>
                        <Col>
                            <TypeSelector
                                initialId={values.typeId}
                                placeholder="Select type"
                                value={values.typeId}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.typeId}
                                error={errors.typeId} />

                            <ErrorMessage name="typeId" component="div" className="invalid-feedback" />
                    </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col>
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <Field name="description" type="text" component="textarea" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                                <ErrorMessage name="description" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                    </Row>
                    <h5>Base version for this form</h5>
                    <p>Don't want to start from scratch? Use any existing form as a starting point for this new one</p>
                    <Row>
                        <Col>
                            <FormSelector
                                initialId={values.formId}
                                placeholder="Select form"
                                value={values.formId}
                                onChange={(field, value) => {
                                    setFieldValue(field, value);
                                    console.log("Form Id setting: " + value.value);
                                    this.setState({
                                        formId: value.value
                                    });
                                    setFieldValue("versionId", null);
                                }}
                                onBlur={setFieldTouched}
                                touched={touched.formId}
                                error={errors.formId} />

                            <ErrorMessage name="formId" component="div" className="invalid-feedback" />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col>
                            <VersionSelector
                                formId={this.state.formId}
                                initialId={values.versionId}
                                placeholder="Select version"
                                value={values.versionId}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.versionId}
                                error={errors.versionId} />

                            <ErrorMessage name="formId" component="div" className="invalid-feedback" />
                        </Col>
                    </Row>
                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "Create"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/form-definitions/details/" + this.state.id} />);
        } else {
            return (
                <Layout>
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New form definition
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Register a definition</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/form-definitions" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm()}
                        </CardBody>
                    </Card>
                    </div>
                </Layout>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            this.setState({
                init: true,
                loading: false
            });
        }
    }
}
