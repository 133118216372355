import { ApiClient } from "./ApiClient";

export async function GetAPIDomain() {
    var domain = localStorage.getItem("api-domain");

    if (!domain) {
        const response = await fetch('app-settings/domains');
        const data = await response.json();

        domain = data.domainApi;
        localStorage.setItem("api-domain", domain);
    }

    return domain + "/api";
}

export async function GetRawAPIDomain() {
    var domain = localStorage.getItem("api-domain");

    if (!domain) {
        const response = await fetch('app-settings/domains');
        const data = await response.json();

        domain = data.domainApi;
        localStorage.setItem("api-domain", domain);
    }

    console.log("TEST");

    return domain;
}

export async function GetPulseDomain() {
    var domain = localStorage.getItem("pulse-domain");

    if (!domain) {
        const response = await fetch('app-settings/domains');
        const data = await response.json();

        domain = data.domainPulse;
        localStorage.setItem("pulse-domain", domain);
    }

    return domain;
}

export async function GetAPIHeaders(contentType) {

    var expiry = new Date(Date.parse(localStorage.getItem("expires")));

    if (expiry < new Date()) {
        console.log("Expired");
        await ApiClient.RefreshToken();
    }

    var accessToken = localStorage.getItem("acess-token");
    return new Headers({ 'content-type': contentType ? contentType : 'application/json', 'Authorization': 'Bearer ' + accessToken });
}


export async function GetAPIHeadersPatch() {

    var expiry = new Date(Date.parse(localStorage.getItem("expires")));

    if (expiry < new Date()) {
        console.log("Expired");
        await ApiClient.RefreshToken();
    }

    var accessToken = localStorage.getItem("acess-token");

    return new Headers({ 'content-type': 'application/json-patch+json', 'Authorization': 'Bearer ' + accessToken });
}