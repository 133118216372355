import { ApiResponse } from "../ApiClient";
import { GetAPIDomain, GetAPIHeaders } from "../Helper";

export class FormPreviewsClient {

    static async CreatePreview(formId: string, definition: any) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/form-definitions/" + formId + "/preview", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(definition)
        }));
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/form-previews/" + id, {
            headers: await GetAPIHeaders()
        }));

    }
}