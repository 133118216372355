import { GetAPIDomain, GetRawAPIDomain } from './Helper';

export class ApiResponse {

    constructor() {
        this.successful = false;
        this.authenticated = false;
    }

    static async Create(response: Response) {
        var result = new ApiResponse();

        result.authenticated = true;

        if (!response.ok) {
            result.successful = false;

            if (response.status === 401) {
                ApiClient.LogOut();
                result.authenticated = false;
                window.location.reload();
            } else {
                try {
                    var data = await response.json();
                    result.data = data;
                    result.validationErrors = data.validationErrors;
                } catch (ex) {
                    console.log("No validate errors for this request");
                }
            }
        } else {
            result.successful = true;
            try {
                result.data = await response.json();
            } catch (ex) {
                console.log(ex);
                console.log("No content for this request");
            }
        }

        return result;
    }

    successful: Boolean;
    validationErrors: any;
    authenticated: Boolean;
    data: any;
}

export class ListQueryParams {

    constructor() {
        this.skip = 0;
        this.take = 50;
        this.direction = "ascending"
        this.params = Array<QueryParameter>();
    }

    Sort(sort: string, direction?: string) {
        this.sort = sort;

        if (direction && direction === "desc")
            return this.Descending();
        if (direction && direction !== "desc")
            return this.Ascending();

        return this;
    }

    Descending() {
        this.direction = "descending";
        return this;
    }

    Ascending() {
        this.direction = "ascending";
        return this;
    }

    Paginate(skip: number, take: number) {
        this.skip = skip;
        this.take = take;
        return this;
    }

    Search(value: string) {
        this.WithParam("q", value);
        return this;
    }

    WithStart(value: any) {
        this.WithParam("start", value);
        return this;
    }

    WithEnd(value: any) {
        this.WithParam("end", value);
        return this;
    }

    WithParam(param: string, value: any) {

        this.params.push({
            key: param,
            value: value
        });

        return this;
    }

    skip: number;
    take: number;
    direction: string;
    params: QueryParameter[];
    sort?: string;

    GenerateQueryString() {
        var query = "?skip=" + this.skip + "&take=" + this.take;
        if (this.sort)
            query = query + "&sort=" + this.sort + "&direction=" + this.direction;

        if (this.params) {
            this.params.map(param => {
                if (param.value && param.key) {
                    if (param.value instanceof Date) {
                        query = query + "&" + param.key + "=" + (<Date>param.value).toISOString();
                    } else {
                        query = query + "&" + param.key + "=" + param.value;
                    }
                }
            });
        }

        return query;
    }
}

export class QueryParameter {
    key?: string;
    value?: any;
}


export class ApiClient {

    //******************************
    // AUTHENTICATION
    //******************************

    static refresh_lock = false;

    //SIGN IN
    static async SignIn(username: string, password: string, setError?: Function) {

        let formData = new URLSearchParams();
        formData.append('username', username);
        formData.append('password', password);
        formData.append('client_id', '4d2c5652-c3e0-443f-b317-ed1ba44a86bc');
        formData.append('grant_type', 'password');

        const response = await fetch((await GetRawAPIDomain()) + "/connect/token", {
            body: formData,
            method: "post"
        });

        if (!response.ok) {

            localStorage.clear();

            if (setError) {
                const data = await response.json();
                setError(data.error_description);
            }

            return false;
        }

        const data = await response.json();

        localStorage.setItem("acess-token", data.access_token);
        localStorage.setItem("refresh-token", data.refresh_token);
        localStorage.setItem("expires", new Date((new Date()).getTime() + (50 * 60 * 1000)).toISOString());

        return true;
    }

    //REFRESH TOKEN
    static async RefreshToken() {

        if (!this.refresh_lock) {
            this.refresh_lock = true;

            var token = localStorage.getItem("refresh-token");

            if (!token) {
                localStorage.clear();
                return;
            }

            let formData = new URLSearchParams();
            formData.append('refresh_token', token);
            formData.append('client_id', '4d2c5652-c3e0-443f-b317-ed1ba44a86bc');
            formData.append('grant_type', 'refresh_token');

            const response = await fetch((await GetRawAPIDomain()) + "/connect/token", {
                body: formData,
                method: "post"
            });

            if (!response.ok) {
                localStorage.clear();
                return false;
            }

            const data = await response.json();

            localStorage.setItem("acess-token", data.access_token);
            localStorage.setItem("refresh-token", data.refresh_token);
            localStorage.setItem("expires", new Date((new Date()).getTime() + (50 * 60 * 1000)).toISOString());

            this.refresh_lock = false;

            return true;
        }
    }

    //SIGN OUT
    static LogOut() {
        localStorage.removeItem("acess-token");
        localStorage.removeItem("refresh-token");
        localStorage.clear();
    }

    //IS AUTHENTICATED
    static IsAuthenticated() {

        if (localStorage == null)
            return false;

        if (localStorage.getItem("acess-token") == null)
            return false;

        var token = localStorage.getItem("acess-token");
        if (token != null && token.length === 0)
            return false;

        return true;
    }

    //BEGIN PASSWORD RESET
    static async BeginPasswordReset(email: string) {
        const response = await fetch((await GetAPIDomain()) + "/reset-password/begin", {
            method: 'post',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify({
                "email": email,
                "isForCRM": true
            })
        });

        return (await response.json()).successful;
    }

    //COMPLETE PASSWORD RESET
    static async CompletePasswordReset(code: string, password: string) {

        const response = await fetch((await GetAPIDomain()) + "/reset-password/complete", {
            method: 'post',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify({
                "code": code,
                "password": password
            })
        });

        return ApiResponse.Create(response);
    }

}
