import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { InvitationsClient } from '../../infrastructure/api/InvitationsClient';
import { Layout } from '../../shared/Layout';

export class InvitesNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false,
            authenticated: true,
            page: "invite"
        };

    }

    renderForm() {
        return (
            <div>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5> Invite user to help manage your organisation
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                </h5>
                                <span className="d-block m-t-5 text-muted">Send an invitation to a user to manage your organisation</span>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    <Link style={{ float: "right" }} to="/invitations" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>

                    <CardBody>

                        <Formik
                            initialValues={{
                                name: this.state.name,
                                surname: this.state.surname,
                                email: this.state.email
                            }}
                            onSubmit={async (fields, { setErrors, setFieldError }) => {

                                var response = await InvitationsClient.InviteUser(fields.name, fields.surname, fields.email);

                                if (!response.successful) {

                                    response.validationErrors.map(error => {
                                        setFieldError(error.key, error.message);
                                        return {
                                            [error.key]: error.message
                                        };
                                    });

                                } else {
                                    this.setState({ page: "next", id: response.data.id });
                                }

                            }}
                        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                            <Form>

                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="name">Name</label>
                                            <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="surname">Surname</label>
                                            <Field name="surname" type="text" className={'form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')} />
                                            <ErrorMessage name="surname" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>
                                </Row>

                                <div className="form-group mt-3">
                                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                        {!isSubmitting && "SEND INVITATION"}
                                        {isSubmitting && <Spinner animation="border" />}
                                    </button>
                                </div>
                            </Form>
                        )}</Formik>


                    </CardBody>

                </Card>

            </div>);
    }

    render() {
       if (this.state.page === "next") {
            return (<Redirect to={"/invitations/details/" + this.state.id} />);
       } else {
           return (<Layout>{this.renderForm()}</Layout>);
        }
    }
}
