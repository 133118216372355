import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'react-moment';
import { Badge, Spinner, Card, CardHeader, Button, Row, Col, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Formik, Form, ErrorMessage } from 'formik';
import { permissionContext } from '../../infrastructure/context/permissionContext';
import { RoleSelector } from '../../infrastructure/selectors/RoleSelector';
import { ApiClientsClient } from '../../infrastructure/api/ApiClientsClient';
import { Layout } from '../../shared/Layout';

export class ApiClientDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false, restoreLoading: false,
            modal: false
        };

        this.suspend = this.suspend.bind(this);
        this.restore = this.restore.bind(this);
    }

    async toggleModal() {
        this.setState({ modal: !this.state.modal })
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }


    async suspend() {
        this.setState({ restoreLoading: true });
        await ApiClientsClient.Suspend(this.props.match.params.id);
        this.populateData(this.props.match.params.id);
        this.setState({ restoreLoading: false });
    }

    async restore() {
        this.setState({ restoreLoading: true });
        await ApiClientsClient.Restore(this.props.match.params.id);
        this.populateData(this.props.match.params.id);
        this.setState({ restoreLoading: false });
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/api-clients" />);
        } else {
            return (
                <Layout>
                <div>
                    {this.state.data && <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/api-clients" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>API Client details </span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}

                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        {this.state.data &&

                                            <permissionContext.Consumer>
                                                {({ permissions }) => {

                                                return (permissions.includes("api.clients.modify") && <Link style={{ float: "right" }} to={"/api-clients/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>);

                                                }}
                                            </permissionContext.Consumer>}

                                        <permissionContext.Consumer>
                                            {({ permissions }) => {

                                                return (permissions.includes("api.clients.modify") &&<>
                                        {this.state.data && this.state.data.status === "active" &&
                                            <button style={{ float: "right" }} onClick={() => { this.toggleModal(); }} className="btn btn-outline-dark mr-2 mt-2 mb-2">
                                                {!this.state.restoreLoading && "Suspend"}
                                                {this.state.restoreLoading && <Spinner animation="border" />}
                                            </button>}
                                        {this.state.data && this.state.data.status === "suspended" &&
                                            <button style={{ float: "right" }} onClick={() => { this.restore(); }} className="btn btn-outline-dark mr-2 mt-2 mb-2">
                                                {!this.state.restoreLoading && "Restore"}
                                                {this.state.restoreLoading && <Spinner animation="border" />}
                                                        </button>}

                                                </>)}}

                                        </permissionContext.Consumer>
                                        <permissionContext.Consumer>
                                            {({ permissions }) => {

                                                return (permissions.includes("permission.modify") && <button style={{ float: "right" }} onClick={() => { this.setState({ panel: "set-role" }); }} className="btn btn-outline-dark mr-2 mt-2 mb-2">
                                                    Set role
                                        </button>);
                                            }}
                                        </permissionContext.Consumer>

                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>}

                    {this.state.panel === "set-role" && <Card>
                        <CardBody>
                            <Formik
                                initialValues={{
                                    roleId: this.state.roleId
                                }}
                                onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                    var response = await ApiClientsClient.SetRole(this.props.match.params.id, fields.roleId ? fields.roleId.value : null);

                                    if (!response.successful) {
                                        response.validationErrors.map(error => {
                                            setFieldError(error.key, error.message);
                                            return {
                                                [error.key]: error.message
                                            };
                                        });
                                    } else {
                                        this.setState({ panel: "none" });
                                        this.populateData(this.props.match.params.id);
                                    }
                                }}
                            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                <Form>
                                    <Row>
                                        <Col>
                                                <RoleSelector
                                                    initialId={values.roleId}
                                                    placeholder="Select role"
                                                    value={values.roleId}
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    touched={touched.roleId}
                                                    error={errors.roleId} />

                                            <ErrorMessage name="roleId" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="mt-4">
                                                <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                    {!isSubmitting && "Save"}
                                                    {isSubmitting && <Spinner animation="border" />}
                                                </button>

                                                <button onClick={() => { this.setState({ panel: "none" }); }} className="btn btn-outline-dark mr-2 mt-2">
                                                    Cancel
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            )}</Formik>
                        </CardBody>
                    </Card>}

                    {this.state.data && <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <h6>Details</h6>
                                    <hr />

                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Name:</td>
                                                <td>
                                                    {this.state.data.name && this.state.data.name}
                                                    {!this.state.data.name && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Client Id:</td>
                                                <td>
                                                    {this.state.data.id}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Status:</td>
                                                <td>
                                                    {this.state.data.status === "active" && <Badge color="success">Active</Badge>}
                                                    {this.state.data.status === "suspended" && <Badge color="secondary">Access suspended</Badge>}
                                                </td>
                                            </tr>
                                            {this.state.data.roles && this.state.data.roles.length > 0 &&
                                                <tr>
                                                    <td className="pr-2">Role:</td>
                                                    <td>{this.state.data.roles[0].name}</td>
                                                </tr>}
                                            <tr>
                                                <td className="pr-2">Created:</td>
                                                <td>
                                                    {this.state.data.created && <Moment utc local format="ddd DD MMM, h:mm a">{this.state.data.created}</Moment>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>}


                    <Modal isOpen={this.state.modal}>
                        <ModalHeader toggle={() => this.toggleModal()}>Are you sure?</ModalHeader>
                        <ModalBody>
                            <p>Suspending the client will prevent it from authenticating with the API.</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => this.toggleModal()}>Cancel</Button>
                            <Button color="primary" onClick={() => {
                                this.suspend();
                                this.toggleModal();
                            }
                            }>I'm sure, suspend the client</Button>
                        </ModalFooter>
                    </Modal>


                    </div>
                </Layout>
            );
        }
    }

    async populateData(id) {

        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await ApiClientsClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {

                if (response.data.roles) {
                    if (response.data.roles.length > 0)
                        this.setState({ roleId: response.data.roles[0].id });
                }

                this.setState({ data: response.data, loading: false });
            }
        }
    }

}
