import React, { Component } from 'react';
import { Container, Spinner, Alert, Card, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Link, Redirect } from 'react-router-dom';
import { ApiClient } from '../../infrastructure/ApiClient';
import { InvitationsClient } from '../../infrastructure/api/InvitationsClient';
import { Layout } from '../../shared/Layout';

export class CheckInvitationCode extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true, success: false, screen: "" };
    }

    async componentDidMount() {
        if (ApiClient.IsAuthenticated()) {
            ApiClient.LogOut();
        }

        var result = await InvitationsClient.ValidateCode(this.props.match.params.id);
        if (result.data && result.data.status === "active") {
            this.setState({
                screen: "sign-up",
                email: result.data.email
            });
        } else {
            this.setState({
                screen: "invalid-code",
                email: result.data.email
            });
        }
    }

    renderSignUpAcceptInvite() {

        return (<Formik
            initialValues={{
                name: '',
                surname: '',
                mobile: '',
                password: ''
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.name) {
                    setFieldError("name", "Please specify your name");
                    return;
                }


                var response = await InvitationsClient.AcceptInvite(this.props.match.params.id, fields.name, fields.surname, this.state.email, fields.mobile, fields.password);

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ screen: "next-sign-in" });
                }

            }}
            render={({ errors, status, touched, isSubmitting }) => (
                <Form autocomplete="off">
                    <h5>Complete account</h5>
                    <p>Please let us know a little about yourself</p>
                    <hr />
                    <div className="form-group">
                        <Field name="name" placeholder="Your name" type="text" className={'bgWhite form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                        <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                    </div>
                    <div className="form-group">
                        <Field name="surname" placeholder="Your surname" type="text" className={'bgWhite form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')} />
                        <ErrorMessage name="surname" component="div" className="invalid-feedback text-left" />
                    </div>
                    <div className="form-group">
                        <Field name="mobile" autoComplete="off" placeholder="Mobile" type="text" className={'bgWhite form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')} />
                        <ErrorMessage name="mobile" component="div" className="invalid-feedback text-left" />
                    </div>
                    <hr />
                    <h5>Choose password</h5>
                    <p>This is the password you will use to sign in</p>
                    <hr />
                    <div className="form-group">
                        <input style={{ opacity: "0", height: "0", float: "left", margin: "0", padding: "0" }} type="text" name="fakeusernameremembered" />
                        <Field name="password" placeholder="Password" type="password" className={'bgWhite form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                        <ErrorMessage name="password" component="div" className="invalid-feedback text-left" />
                    </div>
                    <hr />
                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "ACCEPT INVITATION"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}
        />);
    }

    render() {

        return (<Layout>
            <Card style={{ maxWidth: "600px", width: "100%" }}>
                <CardBody>

                    {this.state.screen === "sign-up" && this.renderSignUpAcceptInvite()}
                    {this.state.screen === "next-sign-in" && <Redirect to="/sign-in" />}
                    {this.state.screen === "invalid-code" && <div>
                        <Container>
                            <Alert color="danger">
                                <h5>Code invalid</h5>
                                <p>The invitation link is no longer valid.</p>
                            </Alert>
                            <Link className="btn mr-2 btn-outline-dark mt-2" to="/sign-in">Go to sign in page</Link>
                        </Container>
                    </div>}

                </CardBody>
            </Card></Layout>
        );

    }
}
