import { ListQueryParams, ApiResponse } from "../ApiClient";
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from "../Helper";

export class UserQueryParams extends ListQueryParams {

    WithShowSuspended(value: any) {
        if (!value)
            this.WithParam("status", "active");
        return this;
    }

    WithRoleId(value: any) {
        this.WithParam("roleId", value);
        return this;
    }

    WithStatus(value: any) {
        this.WithParam("status", value);
        return this;
    }

}

export class UsersClient {

    static async List(params: UserQueryParams) {

        var query = (await GetAPIDomain()) + "/users" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async SetRole(id: string, roleId: any) {

        var query = (await GetAPIDomain()) + "/users/" + id + "/set-roles";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post',
            body: JSON.stringify({
                "roleIds": (roleId && roleId.value) ? [roleId.value] : []
            })
        }));
    }

    static async Suspend(id: string) {

        var query = (await GetAPIDomain()) + "/users/" + id + "/suspend";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post'
        }));
    }
    static async Restore(id: string) {

        var query = (await GetAPIDomain()) + "/users/" + id + "/restore";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post'
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/users/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }


    static async Update(id: string, name: string, surname: string, email: string, mobile?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + id, {
            method: 'PATCH',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            },
            {
                "op": "replace",
                "path": "surname",
                "value": surname
            },
            {
                "op": "replace",
                "path": "email",
                "value": email
            },
            {
                "op": "replace",
                "path": "mobile",
                "value": mobile
            }])
        }));
    }
}