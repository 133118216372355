import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FormTypesClient } from '../../infrastructure/api/FormTypesClient';
import { Layout } from '../../shared/Layout';

export class FormTypesNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, screen: "initial"
        };

    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        return (<Formik
            initialValues={{
                name: '',
                description: ''
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.name) {
                    setFieldError("name", "Please specify the form name");
                    return;
                }

                if (!fields.description) {
                    setFieldError("description", "Please specify the form description");
                    return;
                }


                var response = await FormTypesClient.Create(fields.name, fields.description);

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="name">Name*</label>
                            <Field name="name" placeholder="E.g. Form Type 01" type="text" className={'bgWhite form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                            <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                        </div>
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col>
                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <Field name="description" type="text" component="textarea" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                            <ErrorMessage name="description" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                </Row>
                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "Create"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/form-types/details/" + this.state.id} />);
        } else {
            return (
                <Layout>
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New form type
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Register a type</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/form-types" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm()}
                        </CardBody>
                    </Card>
                    </div>
                </Layout>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            this.setState({
                init: true,
                loading: false
            });
        }
    }
}
