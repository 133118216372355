import { ListQueryParams, ApiResponse } from "../ApiClient";
import { GetAPIDomain, GetAPIHeaders } from "../Helper";

export class PermissionQueryParams extends ListQueryParams {

}

export class PermissionsClient {

    static async List(params: PermissionQueryParams) {

        var query = (await GetAPIDomain()) + "/permissions" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async ListGrouped(params: PermissionQueryParams) {

        var query = (await GetAPIDomain()) + "/permissions/grouped" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async ForCurrentUser() {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/permissions/for/current-user", {
            headers: await GetAPIHeaders()
        }));

    }

}