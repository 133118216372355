import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup, Badge } from 'reactstrap';
import Moment from 'react-moment';
import { TableHeader } from '../../infrastructure/TableHeader';
import { permissionContext } from '../../infrastructure/context/permissionContext';
import { FormDefinitionQueryParams, FormDefinitionsClient } from '../../infrastructure/api/FormDefinitionsClient';

export class FormDefinitionsTable extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false, total: 0, skip: 0, take: 50, formStatus: "", authenticated: true, search: "", sort: "name", direction: "asc", showArchived: false };

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleShowArchived = this.handleShowArchived.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
    }

    handleShowArchived = (evt) => {
        this.setState({
            showArchived: evt.target.checked
        }, () => this.update());
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    handleStatusChange(evt) {
        this.setState({
            formStatus: evt.target.value
        }, () => this.update());
    }

    async clear() {
        this.setState({
            search: "",
            formStatus: "",
            skip: 0
        }, () => this.update());
    }

    componentDidMount() {
        this.populateData(this.state.skip, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    renderTable(data) {

        var columns = [
            { name: "Name", sort: "name" },
            { name: "Visibility", sort: "visibility" },
            { name: "Type", sort: "type" },
            { name: "Created", sort: "created" },
            { name: "", sort: "", static: true }];

        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={columns}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />
                <tbody>
                    {data.map(item =>
                        <tr key={item.id}>
                            <td>
                                {item.name && <span>{item.name}</span>}
                                {!item.name && <i>Not specified</i>}

                                {item.integration == "oneHub" && <img title="This form is in the OneHub platform" src="https://portal.onehub.one.nz/Content/img/logo-one.png" width="25" style={{marginLeft: "10px"}} />}
                                
                            </td>
                            <td>
                                {item.integration == "oneHub" && <Badge color="secondary">Not applicable</Badge>}
                                {item.integration == "none" && item.visibility === "draft" && <Badge color="secondary">Draft</Badge>}
                                {item.integration == "none" && item.visibility === "public" && <Badge color="success">Public</Badge>}
                                {item.integration == "none" && item.visibility === "archived" && <Badge color="default">Archived</Badge>}
                            </td>
                            <td>
                                {item.type && <span>{item.type.name}</span>}
                                {!item.type && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.created && <Moment utc local format="ddd DD MMM, h:mm a">{item.created}</Moment>}
                            </td>
                            <td>
                                <Link key={item.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/form-definitions/details/" + item.id}>Details</Link>
                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="8"><h4 className="text-muted text-center mt-3"><i>No definitions to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>
        );
    }

    render() {


        const {
            hideFilter,
            description
        } = this.props;


        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Form definitions ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">{description} {!description && "List of form definitions"}</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                        <permissionContext.Consumer>
                                            {({ permissions }) => {

                                                return (permissions.includes("form.definitions.modify") && <Link style={{ float: "right" }} to="/form-definitions/new" className="btn mr-2 btn-outline-dark mt-2 mb-2"> New</Link>);

                                            }}
                                        </permissionContext.Consumer>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                    </Card>
                    <Card>
                        {!hideFilter && <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.formStatus} onChange={this.handleStatusChange} >
                                            <option value="">All statuses</option>
                                            <option value="draft">Draft</option>
                                            <option value="public">Public</option>
                                            {this.state.showArchived && <option value="archived">Archived</option>}
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        <InputGroup>
                                            <Input type="text" placeholder="Search" value={this.state.search} onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {(this.state.search || this.state.formStatus) &&
                                                    <button onClick={() => { this.clear(); }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>}


                        {!hideFilter && <CardHeader>
                            <Row>
                                <Col></Col>
                                <Col md="4">
                                    <div className="form-group">
                                        <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                            <li className="list-inline-item">
                                                <label style={{ margin: "0" }}>
                                                    <input name="showArchived"
                                                        type="checkbox"
                                                        checked={this.state.showArchived}
                                                        onChange={this.handleShowArchived} />  Show archived
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>}

                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async populateData(skip, take) {

        const {
            roleId
        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var query = new FormDefinitionQueryParams();

            var response = await FormDefinitionsClient.List(query
                .Paginate(skip, take)
                .Search(this.state.search)
                .WithVisibility(this.state.formStatus)
                .WithShowArchived(this.state.showArchived ? true : false)
                .Sort(this.state.sort, this.state.direction));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }
        }
    }
}
