import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Spinner, Card, Row, Col, CardBody, Input, FormGroup, Button, CardHeader } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { MinLayout } from '../../shared/MinLayout';
import { FormPreviewsClient } from '../../infrastructure/api/FormPreviewsClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

export class FormPreview extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            loading: false,
            authenticated: true,
            next: false,
            restoreLoading: false,
            modal: false,
            expandAllState: "collapsed",
            activeTab: '1',
            questionStack: [],
            currentQuestion: null,
            endQuiz: false
        };

    }

    gotoNextQuestionOrEnd(source, answer) {

        console.log("Next quetion triggered");
        console.log(answer);

        var nextId = this.getNextQuestionId(source, answer);
        if (nextId) {
            console.log(this.getDefinitionFromId(nextId));
            this.state.questionStack.push({
                question: this.getDefinitionFromId(source),
                answer: answer,
                next: this.getDefinitionFromId(nextId)
            });

            this.setState({
                questionStack: this.state.questionStack,
                currentQuestion: this.getDefinitionFromId(nextId)
            });

        } else {
            this.setState({
                endQuiz: true
            })
        }
    }

    //If null, then done
    getNextQuestionId(source, answer){

        //Check if end step
        var definition = this.getDefinitionFromId(source);

        if (definition === null) {
            console.log("No definition found - ending quiz");
            return;
        }

        if (definition.settings.endFlow) {
            console.log("Question is endFlow - ending quiz");
            return;
        }

        //Check logic
        for (var i = 0; i < this.state.definition.logic.length; i++) {

            var logicItem = this.state.definition.logic[i];

            if (logicItem.source === source) {
                if (logicItem.condition.op === "always")
                    return logicItem.destination;
                if (logicItem.condition.op === "equal" && logicItem.condition.value === answer)
                    return logicItem.destination;
            }

        }

        //Check next question
        for (var j = 0; j < this.state.definition.fields.length; j++) {
            if (this.state.definition.fields[j].id === source) {
                if (j < this.state.definition.fields.length - 1)
                    return this.state.definition.fields[j + 1].id;
            }
        }

    }

    getDefinitionFromId(id) {
        if (this.state.definition && this.state.definition.fields && this.state.definition.fields.length > 0) {
            for (var i = 0; i < this.state.definition.fields.length; i++) {
                if (this.state.definition.fields[i].id === id) {
                    return this.state.definition.fields[i];
                }
            }
        }
    }

    getTitleForQuestionId(id) {
        if (this.state.definition && this.state.definition.fields && this.state.definition.fields.length > 0) {
            for (var i = 0; i < this.state.definition.fields.length; i++) {

                if (this.state.definition.fields[i].id === id) {
                    if (this.state.definition.fields[i].title)
                        return this.state.definition.fields[i].title;
                    return this.state.definition.fields[i].content;
                }
                   
            }
        }
        return "Unknown";
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    render() {

        console.log(this.state.currentQuestion);


        if (this.state.next) {
            return (<Redirect to="/form-definitions" />);
        } else {
            return (
                <MinLayout>
                    <div>
                        <ReactTooltip />

                        <Row style={{ width: "800px" }}>

                            <Col style={{ maxHeight:"400px", overflow: "scroll"}}>
                                <h6 className="mt-4 mb-3">History</h6>
                                {this.state.questionStack && this.state.questionStack.length === 0 && <i>Empty</i>}
                                {this.state.questionStack && this.state.questionStack.length > 0 && [].concat(this.state.questionStack).reverse().map((item, ix) => {
                                    return (<Card className="mb-2">
                                        <CardHeader><i>Step {this.state.questionStack.length - ix}</i></CardHeader>
                                        <CardHeader>{item.question.id} <FontAwesomeIcon icon={faLongArrowAltRight} /> {item.next.id}</CardHeader>
                                        {item.answer && <CardBody>Answer: <strong>{item.answer}</strong></CardBody>}
                                    </Card>);
                                })}
                            </Col>
                            <Col>
                                <h3 className="text-center mb-4">

                                    {this.state.questionStack && this.state.questionStack.length > 0 && < Button style={{ marginTop: "5px", float: "left" }} className="btn btn-default pull-left" onClick={() => {

                                        var current = this.state.questionStack.pop();
                                        this.setState({
                                            currentQuestion: current.question,
                                            questionStack: this.state.questionStack,
                                            endQuiz: false
                                        });

                                    }}>
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                    </Button>}

                                    Survey Preview
                                </h3>
                                {this.state.loading && <Spinner />}

                                {this.state.currentQuestion && <Card className="mt-4" style={{ width: "550px" }}>
                                    {!this.state.endQuiz && <CardBody>

                                        {!this.state.currentQuestion && <i>This quiz has no questions</i>}
                                        {this.state.currentQuestion && <div>
                                            {this.state.currentQuestion.type === "statement" && <Row>
                                                <Col className="text-center">
                                                    <h6 className="pb-4 pt-3">{this.state.currentQuestion.content}</h6>
                                                    <div className="text-center" style={{ margin: "auto", width: "100%" }}>
                                                        <Button onClick={() => this.gotoNextQuestionOrEnd(this.state.currentQuestion.id)} className="btn btn-light w-100" style={{ margin: "auto", width: "120px" }}>
                                                            {this.state.currentQuestion.nextButton.text}
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>}

                                            {this.state.currentQuestion.type === "buttonOptions" && <Row>
                                                <Col className="text-center">
                                                    <h6 className="pb-4 pt-3">{this.state.currentQuestion.title}</h6>
                                                    <div className="text-center" style={{ margin: "auto", width: "100%" }}>
                                                        {this.state.currentQuestion.options && this.state.currentQuestion.options.map(option => <Button onClick={() => this.gotoNextQuestionOrEnd(this.state.currentQuestion.id, option.value)} className="btn btn-light mt-2 w-100" style={{ margin: "auto" }}>
                                                            {option.displayText}
                                                        </Button>)}
                                                    </div>
                                                </Col>
                                            </Row>}

                                            {this.state.currentQuestion.type === "longText" && <Row>
                                                <Col className="text-center">
                                                    <h6 className="pb-4 pt-3">{this.state.currentQuestion.title}</h6>
                                                    
                                                    <FormGroup className="pb-4">
                                                        <Input type="textarea" name="text" id={this.state.currentQuestion.id+"-text"} />
                                                    </FormGroup>
                                                    
                                                    <Button onClick={() => this.gotoNextQuestionOrEnd(this.state.currentQuestion.id, document.getElementById(this.state.currentQuestion.id+"-text").value)} className="btn btn-light w-100" style={{ margin: "auto" }}>
                                                        {this.state.currentQuestion.nextButton.text}
                                                    </Button>
                                                </Col>
                                            </Row>}

                                            {this.state.currentQuestion.type === "likert" && <Row>
                                                <Col className="text-center">
                                                    <h6 className="pb-4 pt-3">{this.state.currentQuestion.title}</h6>
                                                   
                                                    <div className="text-center" style={{ margin: "auto", width: "100%" }}>
                                                        {this.state.currentQuestion.options && this.state.currentQuestion.options.map(option => <Button onClick={() => this.gotoNextQuestionOrEnd(this.state.currentQuestion.id, option.value)} className="btn btn-light w-100 mt-2" style={{ margin: "auto" }}>
                                                            {option.displayText}
                                                        </Button>)}
                                                    </div>
                                                </Col>
                                            </Row>}

                                            {this.state.currentQuestion.type === "rating" && <Row>
                                                <Col className="text-center">
                                                    <h6>{this.state.currentQuestion.title}</h6>
                                                    <hr />
                                                    <div className="text-center" style={{ margin: "auto", width: "100%" }}>
                                                        {this.state.currentQuestion.options && this.state.currentQuestion.options.map(option => <Button onClick={() => this.gotoNextQuestionOrEnd(this.state.currentQuestion.id, option.value)} className="btn btn-light mr-2 mt-2" style={{ margin: "auto" }}>
                                                            {option.displayText}
                                                        </Button>)}
                                                    </div>
                                                    <hr />
                                                    <Row>
                                                        <Col style={{"textAlign": "left"}}>
                                                            {this.state.currentQuestion.settings.start}
                                                        </Col>
                                                        <Col style={{ "textAlign": "right" }}>
                                                            {this.state.currentQuestion.settings.end}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>}
                                        </div>}
                                    </CardBody>}
                                    {this.state.endQuiz && <h4 className="p-4 text-center">The quiz is complete</h4>}
                                </Card>}
                                <div className="text-center mt-4">
                                    <a href="#" onClick={() => window.close() }>Close preview</a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </MinLayout>
            );
        }
    }

    async populateData(id) {

        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await FormPreviewsClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {

                this.setState({ data: response.data, loading: false });

                if (response.data.definition && response.data.definition.fields && response.data.definition.fields.length > 0)
                    this.setState({ currentQuestion: response.data.definition.fields[0] });

                if (response.data.definition) {

                    response.data.definition.fields.map(item => item.isOpen = false);

                    this.setState({ definition: response.data.definition, loading: false }, () => {
                        ReactTooltip.rebuild();
                    });
                }
            }
        }
    }

}
