import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'react-moment';
import { TabContent, TabPane, Nav, NavItem, NavLink, Badge, Spinner, Card, CardHeader, Row, Col, CardBody, ListGroup, ListGroupItem, Collapse, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignJustify, faAngleRight, faBuilding, faCheckCircle, faChevronCircleDown, faChevronCircleRight, faStar, faChevronDown, faChevronLeft, faCross, faStamp, faList, faStickyNote } from '@fortawesome/free-solid-svg-icons'
import { permissionContext } from '../../infrastructure/context/permissionContext';
import { FormDefinitionsClient } from '../../infrastructure/api/FormDefinitionsClient';
import ReactTooltip from 'react-tooltip';
import { SectionTypes } from '../../infrastructure/constants/SectionTypes';
import { FormVersionsTable } from '../form-versions/FormVersionsTable';
import classnames from 'classnames';
import { Layout } from '../../shared/Layout';

export class FormDefinitionDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false, restoreLoading: false,
            modal: false,
            expandAllState: "collapsed",
            activeTab: '1'
        };

        this.toggle = this.toggle.bind(this);

    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    getTitleForQuestionId(id) {
        if (this.state.definition && this.state.definition.fields && this.state.definition.fields.length > 0) {
            for (var i = 0; i < this.state.definition.fields.length; i++) {

                if (this.state.definition.fields[i].id === id) {
                    if (this.state.definition.fields[i].title)
                        return this.state.definition.fields[i].title;
                    return this.state.definition.fields[i].content;
                }
                   
            }
        }
        return "Unknown";
    }

    updateExpandAllState() {

        if (!this.state.definition)
            return;

        if (this.state.definition.fields.every(e => e.isOpen === true)) {
            console.log("expanded");
            this.setState({
                expandAllState: "expanded"
            });
            return;
        }

        if (this.state.definition.fields.every(e => !e.isOpen === false)) {
            console.log("collapsed");
            this.setState({
                expandAllState: "collapsed"
            });
            return;
        }

        if (this.state.definition.fields.some(e => e.isOpen === true) && this.state.definition.fields.some(e => e.isOpen === false)) {
            console.log("mixed");
            this.setState({
                expandAllState: "mixed"
            });
            return;
        }

        console.log("default");
        this.setState({
            expandAllState: "collapsed"
        });
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/form-definitions" />);
        } else {
            return (
                <Layout>
                <div>

                    <ReactTooltip />
                    {this.state.data && <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/form-definitions" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Form details </span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}

                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        {this.state.data &&

                                            <permissionContext.Consumer>
                                                {({ permissions }) => {

                                                    return (permissions.includes("form.definitions.modify") && <Link style={{ float: "right" }} to={"/form-definitions/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>);

                                                }}
                                            </permissionContext.Consumer>}

                                    </div>
                                    <div style={{ paddingRight: "10px" }}>

                                        {this.state.data &&

                                            <permissionContext.Consumer>
                                                {({ permissions }) => {

                                                    return (permissions.includes("form.definitions.modify") && <Link style={{ float: "right" }} to={"/form-definitions/edit-definition/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Update form</Link>);

                                                }}
                                            </permissionContext.Consumer>}

                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>}

                    {this.state.data && <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <h6>Details</h6>
                                    <hr />

                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Name:</td>
                                                <td>
                                                    {this.state.data.name && this.state.data.name}
                                                    {!this.state.data.name && <i>Not specified</i>}
                                                </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Visibility:</td>
                                                    <td>
                                                        {this.state.data.integration == "oneHub" && <Badge color="secondary">Not applicable</Badge>}
                                                        {this.state.data.integration == "none" && this.state.data.visibility === "draft" && <Badge color="secondary">Draft</Badge>}
                                                        {this.state.data.integration == "none" && this.state.data.visibility === "public" && <Badge color="success">Public</Badge>}
                                                        {this.state.data.integration == "none" && this.state.data.visibility === "archived" && <Badge color="default">Archived</Badge>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Integration:</td>
                                                    <td>
                                                        {this.state.data.integration == "none" && <Badge color="secondary">None</Badge>}
                                                        {this.state.data.integration == "oneHub" && <Badge color="success">OneHub</Badge>}
                                                    </td>
                                                </tr>
                                            <tr>
                                                <td className="pr-2">Type:</td>
                                                <td>
                                                    {this.state.data.type && this.state.data.type.name}
                                                    {!this.state.data.type && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Created:</td>
                                                <td>
                                                    {this.state.data.created && <Moment utc local format="ddd DD MMM, h:mm a">{this.state.data.created}</Moment>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>}


                    {this.state.data && <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Current</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        History</NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                    </Card>}

                    {this.state.data && <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && <div>

                                <Card>
                                    {!this.state.definition && <CardBody>
                                        <h6>No form published, publish a version to make it available for use</h6>
                                    </CardBody>}
                                    {this.state.definition && <CardBody>
                                        <Row>
                                            <Col style={{ marginLeft: 20 }}>
                                                <Button className="btn btn-light float-left" onClick={() => {
                                                    if (this.state.expandAllState === "expanded") {
                                                        this.state.definition.fields.map(item => item.isOpen = false);
                                                        this.setState({
                                                            definition: this.state.definition
                                                        });
                                                        this.updateExpandAllState();
                                                    } else {
                                                        this.state.definition.fields.map(item => item.isOpen = true);
                                                        this.setState({
                                                            definition: this.state.definition
                                                        });
                                                        this.updateExpandAllState();
                                                    }
                                                }}>
                                                    {this.state.expandAllState === "collapsed" && <FontAwesomeIcon icon={faChevronCircleRight} />}
                                                    {this.state.expandAllState === "mixed" && <FontAwesomeIcon icon={faChevronDown} />}
                                                    {this.state.expandAllState === "expanded" && <FontAwesomeIcon icon={faChevronCircleDown} />}
                                                </Button>
                                                <h6 className="pt-2">Form logic</h6>
                                            </Col>
                                        </Row>

                                        <hr />

                                        {this.state.definition.fields && this.state.definition.fields.map(item =>
                                            <Card className="bg-light" id={item.id} key={item.id}>
                                                <CardHeader>
                                                    <Row>
                                                        <Col>
                                                            <Button className="btn btn-light float-left" onClick={() => {
                                                                item.isOpen = !item.isOpen;
                                                                this.setState(this.state.definition, () => this.updateExpandAllState());

                                                            }}>
                                                                {item.isOpen && <FontAwesomeIcon icon={faChevronCircleDown} />}
                                                                {!item.isOpen && <FontAwesomeIcon icon={faChevronCircleRight} />}
                                                            </Button>
                                                            <div className="pt-2">
                                                                {item.type === SectionTypes.STATEMENT && <h6 className="header-title mt-0 mb-0"><FontAwesomeIcon icon={faAlignJustify} /> Statement</h6>}
                                                                {item.type === SectionTypes.BUTTON_OPTIONS && <h6 className="header-title mt-0 mb-0"><FontAwesomeIcon icon={faList} /> Buttons</h6>}
                                                                {item.type === SectionTypes.SITE && <h6 className="header-title mt-0 mb-0"><FontAwesomeIcon icon={faBuilding} /> Site select</h6>}
                                                                {item.type === SectionTypes.LONG_TEXT && <h6 className="header-title mt-0 mb-0"><FontAwesomeIcon icon={faStickyNote} /> Long text</h6>}
                                                                {item.type === SectionTypes.LIKERT && <h6 className="header-title mt-0 mb-0"><FontAwesomeIcon icon={faStamp} /> Likert</h6>}
                                                                {item.type === SectionTypes.RATING && <h6 className="header-title mt-0 mb-0"><FontAwesomeIcon icon={faStar} /> NPS</h6>}
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            {this.state.definition.fields.indexOf(item) === 0 && <Badge className="mt-2" color="success">Quiz starts here</Badge>}
                                                        </Col>
                                                        <Col className="text-right">
                                                            <div className="pt-2">
                                                                <h6>
                                                                    <small className="text-muted">{item.title && item.title}{!item.title && "Statement"} &#183; <i>{item.id}</i></small></h6>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <Collapse isOpen={item.isOpen}>
                                                    <CardBody>
                                                        <Row>
                                                            <Col>
                                                                {item.type === "statement" && <ListGroup>
                                                                    <p><strong>Content</strong></p>
                                                                    <ListGroupItem className="text-left">{item.content}</ListGroupItem>
                                                                </ListGroup>}

                                                                {(item.type === SectionTypes.SITE || item.type === SectionTypes.LONG_TEXT || item.type === SectionTypes.LIKERT || item.type === SectionTypes.RATING) && <ListGroup>
                                                                    <p><strong>Question</strong></p>
                                                                    <ListGroupItem className="text-left">{item.title}</ListGroupItem>
                                                                </ListGroup>}

                                                                {(item.type === SectionTypes.STATEMENT || item.type === SectionTypes.RATING || item.type === SectionTypes.LONG_TEXT || item.type === SectionTypes.LIKERT) && <ListGroup>
                                                                    <p><strong>Settings</strong></p>
                                                                    <ListGroupItem className="text-left">End flow: {item.settings.endFlow === true && <strong>True</strong>}{item.settings.endFlow === false && <strong>False</strong>}</ListGroupItem>
                                                                    <ListGroupItem className="text-left">Button label: <strong>{item.nextButton.text}</strong></ListGroupItem>
                                                                    {item.type === SectionTypes.RATING && <ListGroupItem className="text-left">End label: <strong>{item.settings.start}</strong></ListGroupItem>}
                                                                    {item.type === SectionTypes.RATING && <ListGroupItem className="text-left">Start label: <strong>{item.settings.end}</strong></ListGroupItem>}
                                                                </ListGroup>}

                                                                {item.type === SectionTypes.SITE && <ListGroup>
                                                                    <p><strong>Settings</strong></p>
                                                                    <ListGroupItem className="text-left">Working from home is {item.settings.includeHome === true && <strong>an option</strong>}{item.settings.includeHome === false && <strong>not an option</strong>}</ListGroupItem>
                                                                    <ListGroupItem className="text-left">Multi-select is {item.settings.multiSelect === true && <strong>enabled</strong>}{item.settings.multiSelect === false && <strong>disabled</strong>}</ListGroupItem>
                                                                </ListGroup>}

                                                                {item.type === SectionTypes.BUTTON_OPTIONS && <ListGroup>
                                                                    <p><strong>Question</strong></p>
                                                                    <ListGroupItem className="text-left">{item.title}</ListGroupItem>
                                                                </ListGroup>}

                                                                {(item.type === SectionTypes.BUTTON_OPTIONS || item.type === SectionTypes.LIKERT) && <ListGroup>
                                                                    <p><strong>Options</strong></p>
                                                                    {!(item.options && item.options.length > 0) && <span><FontAwesomeIcon icon={faCross} /> Not configured</span>}
                                                                    {item.options && item.options.length > 0 && item.options.map(buttonOption => <ListGroupItem className="text-left">{buttonOption.displayText}</ListGroupItem>)}
                                                                </ListGroup>}


                                                                {item.type === SectionTypes.BUTTON_OPTIONS && <ListGroup>
                                                                    <p><strong>Settings</strong></p>
                                                                    <ListGroupItem className="text-left">Multi-select is {item.settings.multiSelect === true && <strong>enabled</strong>}{item.settings.multiSelect === false && <strong>disabled</strong>}</ListGroupItem>
                                                                </ListGroup>}

                                                            </Col>
                                                            <Col>
                                                                <ListGroup>
                                                                    <p><strong>Logic</strong></p>

                                                                    {!(item.settings && item.settings.endFlow === true) && <>



                                                                        {(this.state.definition.logic && this.state.definition.logic.filter(l => l.source === item.id && l.condition.op !== "always").length > 0)
                                                                            &&  <>{this.state.definition.logic.filter(l => l.source === item.id && l.condition.op !== "always").map(logicItem => <ListGroupItem className="text-left">

                                                                            {logicItem.condition && logicItem.condition.op === "equal" && <span>
                                                                                <FontAwesomeIcon icon={faAngleRight} /> If answer is '<strong>{logicItem.condition.value}</strong>' then go to <strong data-tip={this.getTitleForQuestionId(logicItem.destination)} className="clickable" onClick={() => document.getElementById(logicItem.destination).scrollIntoView()}>{logicItem.destination}</strong>
                                                                            </span>}

                                                                            </ListGroupItem>)}

                                                                                <p><strong>Otherwise</strong></p>
                                                                            </>}

                                                                        {!(this.state.definition.logic && this.state.definition.logic.filter(l => l.source === item.id).length > 0) &&
                                                                            <ListGroupItem className="text-left">Proceed to the next item when answered</ListGroupItem>
                                                                        }

                                                                        {(this.state.definition.logic && this.state.definition.logic.filter(l => l.source === item.id && l.condition.op === "always").length > 0)
                                                                            && <>{this.state.definition.logic.filter(l => l.source === item.id && l.condition.op === "always").map(logicItem => <ListGroupItem className="text-left">

                                                                                {logicItem.condition && logicItem.condition.op === "always" && <span>
                                                                                    <FontAwesomeIcon icon={faAngleRight} /> Proceed to question <strong data-tip={this.getTitleForQuestionId(logicItem.destination)} className="clickable" onClick={() => document.getElementById(logicItem.destination).scrollIntoView()}>{logicItem.destination}</strong> when answered
                                                                                </span>}

                                                                            </ListGroupItem>)}
                                                                            </>}

                                                                    </>}


                                                                    {item.settings && item.settings.endFlow === true && <ListGroupItem className="text-left">
                                                                        <span><FontAwesomeIcon icon={faCheckCircle} /> Submit completed form</span>
                                                                    </ListGroupItem>}
                                                                </ListGroup>

                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Collapse >
                                            </Card>)}

                                        {!this.state.definition.fields && <div className="card-box">
                                            <Card className="bg-light">
                                                <CardBody>
                                                    <h6>No form created, use the form builder to define the form</h6>
                                                </CardBody>
                                            </Card>
                                        </div>}


                                    </CardBody>}
                        </Card>


                        </div>}
                    </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <FormVersionsTable formId={this.props.match.params.id} versionChanged={() => this.populateData(this.props.match.params.id)} />}
                        </TabPane>

                    </TabContent>}

                    </div>
                </Layout>
            );
        }
    }

    async populateData(id) {

        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await FormDefinitionsClient.GetPublished(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {

                this.setState({ data: response.data, loading: false });
                if (response.data.definition) {

                    response.data.definition.fields.map(item => item.isOpen = false);

                    this.setState({ definition: response.data.definition, loading: false }, () => {
                        ReactTooltip.rebuild();
                    });
                }
            }
        }
    }

}
