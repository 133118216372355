import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { ApiClient } from '../infrastructure/ApiClient';
import { NavMenu } from './NavMenu';
import { SideBar } from './SideBar';

export class Layout extends Component {
  static displayName = Layout.name;

    render() {
        if (ApiClient.IsAuthenticated()) {
            return (<div className="bg-light">
                <NavMenu />
                <Row style={{ marginRight: 0 }}>
                    <div className="col-md-2">
                        <SideBar />
                    </div>
                    <div className="col-md-10">
                        <div className="container-fluid" style={{ paddingTop: "20px" }}>
                            {this.props.children}
                        </div>
                    </div>
                </Row>

            </div>)
        } else {
            return (
                <div className="auth-wrapper aut-bg-img" style={{ backgroundImage: "url('assets/images/bg-images/bg1.jpg')" }}>
                    {this.props.children}
                </div>
            );
        }
  }
}
