import React, { Component } from 'react';
import { Route } from 'react-router';
import { permissionContext } from './infrastructure/context/permissionContext'
import { PermissionsClient } from './infrastructure/api/PermissionsClient'
import { PrivateRoute } from './infrastructure/PrivateRoute';

import './custom.css'
import { ApiClient } from './infrastructure/ApiClient';
import { Layout } from './shared/Layout';

import { Home } from './components/Home';
import { SignIn } from './components/SignIn';

import { PasswordResetBegin } from './components/pages/PasswordResetBegin';
import { PasswordResetComplete } from './components/pages/PasswordResetComplete';
import { CheckInvitationCode } from './components/pages/CheckInvitationCode';

import { Roles } from './components/roles/Roles';
import { RolesNew } from './components/roles/RolesNew';
import { RolesEdit } from './components/roles/RolesEdit';
import { RolesDetails } from './components/roles/RolesDetails';

import { ApiClients } from './components/api-clients/ApiClients';
import { ApiClientDetails } from './components/api-clients/ApiClientDetails';
import { ApiClientsEdit } from './components/api-clients/ApiClientsEdit';
import { ApiClientsNew } from './components/api-clients/ApiClientsNew';

import { FormDefinitions } from './components/form-definitions/FormDefinitions';
import { FormDefinitionsNew } from './components/form-definitions/FormDefinitionsNew';
import { FormDefinitionDetails } from './components/form-definitions/FormDefinitionDetails';
import { FormDefinitionsEdit } from './components/form-definitions/FormDefinitionsEdit';
import { FormDefinitionsEditDefinition } from './components/form-definitions/FormDefinitionsEditDefinition';
import { FormVersionsEdit } from './components/form-versions/FormVersionsEdit';

import { FormPreview } from './components/form-preview/FormPreview';

import { FormTypes } from './components/form-types/FormTypes';
import { FormTypesNew } from './components/form-types/FormTypesNew';
import { FormTypesDetails } from './components/form-types/FormTypesDetails';
import { FormTypesEdit } from './components/form-types/FormTypesEdit';

import { Users } from './components/users/Users';
import { UserDetails } from './components/users/UserDetails';
import { UsersEdit } from './components/users/UsersEdit';

import { Invites } from './components/invites/Invites';
import { InvitesNew } from './components/invites/InvitesNew';
import { InvitesEdit } from './components/invites/InvitesEdit';
import { InvitesDetails } from './components/invites/InvitesDetails';
import { MinLayout } from './shared/MinLayout';

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {
            initComplete: false,
            permissions: []
        };
        this.refreshApplicationState = this.refreshApplicationState.bind(this);
    }

    async componentDidMount() {
        await this.refreshApplicationState();
    }


    async refreshApplicationState() {

        if (ApiClient.IsAuthenticated()) {

            var permissions = await PermissionsClient.ForCurrentUser();

            if (permissions.successful) {
                this.setState({
                    permissions: permissions.data.data.map(p => p.id),
                    initComplete: true
                });
            }

            this.setState({
                initComplete: true
            });

        } else {
            this.setState({
                permissions: []
            });
        }
    }

    render() {

        const value = {
            permissions: this.state.permissions,
            initComplete: this.state.initComplete,
            refresh: this.refreshApplicationState
        };

        return (
            <permissionContext.Provider value={value}>
                
                    <Route exact path='/sign-in' component={SignIn} />
                    <PrivateRoute exact path='/' component={Home} />

                    <Route exact path='/password-reset/begin' component={PasswordResetBegin} />
                    <Route exact path='/password-reset/complete/:code' component={PasswordResetComplete} />
                    <Route exact path='/invite/accept/:id' component={CheckInvitationCode} />

                    <PrivateRoute exact path='/roles' component={Roles} />
                    <PrivateRoute exact path='/roles/new' component={RolesNew} />
                    <PrivateRoute exact path='/roles/edit/:id' component={RolesEdit} />
                    <PrivateRoute exact path='/roles/details/:id' component={RolesDetails} />

                    <PrivateRoute exact path='/users' component={Users} />
                    <PrivateRoute exact path='/users/details/:id' component={UserDetails} />
                    <PrivateRoute exact path='/users/edit/:id' component={UsersEdit} />
                    
                    <PrivateRoute exact path='/api-clients' component={ApiClients} />
                    <PrivateRoute exact path='/api-clients/new' component={ApiClientsNew} />
                    <PrivateRoute exact path='/api-clients/details/:id' component={ApiClientDetails} />
                    <PrivateRoute exact path='/api-clients/edit/:id' component={ApiClientsEdit} />

                    <PrivateRoute exact path='/form-definitions' component={FormDefinitions} />
                    <PrivateRoute exact path='/form-definitions/new' component={FormDefinitionsNew} />
                    <PrivateRoute exact path='/form-definitions/details/:id' component={FormDefinitionDetails} />
                    <PrivateRoute exact path='/form-definitions/edit/:id' component={FormDefinitionsEdit} />
                    <PrivateRoute exact path='/form-definitions/edit-definition/:id' component={FormDefinitionsEditDefinition} />

                    <PrivateRoute exact path='/form-types' component={FormTypes} />
                    <PrivateRoute exact path='/form-types/new' component={FormTypesNew} />
                    <PrivateRoute exact path='/form-types/details/:id' component={FormTypesDetails} />
                    <PrivateRoute exact path='/form-types/edit/:id' component={FormTypesEdit} />

                    <PrivateRoute exact path='/form-versions/edit/:id' component={FormVersionsEdit} />

                    <PrivateRoute exact path='/invitations' component={Invites} />
                    <PrivateRoute exact path='/invitations/new' component={InvitesNew} />
                    <PrivateRoute exact path='/invitations/details/:id' component={InvitesDetails} />
                    <PrivateRoute exact path='/invitations/edit/:id' component={InvitesEdit} />

                    <PrivateRoute exact path='/form-definitions/preview/:id' component={FormPreview} />
                
            </permissionContext.Provider>
        );
    }
}
