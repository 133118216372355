import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { InputGroup, ButtonGroup, Input, Spinner, Card, CardHeader, Button, Row, Col, CardBody, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { InvitationsClient } from '../../infrastructure/api/InvitationsClient';
import { Layout } from '../../shared/Layout';

export class InvitesDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false,
            activeTab: '1'
        };

        this.cancel = this.cancel.bind(this);
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    render() {
        return (
            <Layout>
            <div>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>
                                    <Link to="/invitations" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                    {this.state.data && <span>Invitation</span>}
                                    {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.name} {this.state.data.surname}</span>}</span>
                                </h5>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    {this.state.data && this.state.data.status === "pending" && <Link style={{ float: "right" }} to={"/invitations/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                    <CardBody>
                        {this.state.data &&
                            <InputGroup>
                                <ButtonGroup className="input-group-prepend">
                                    <span className="input-group-text">Invite link: </span>
                                </ButtonGroup>
                            <Input disabled value={"https://" + window.location.host + "/invite/accept/" + this.state.data.code} />
                            </InputGroup>}
                    </CardBody>
                </Card>
                {this.state.data &&
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <h6>Invitation details</h6>

                                    <table>
                                        <tr>
                                            <td className="pr-2">Name:</td>
                                            <td>
                                                {this.state.data.name && <span>{this.state.data.name} {this.state.data.surname}</span>}
                                                {!this.state.data.name && <i>Not specified</i>}</td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Email:</td>
                                            <td>
                                                {this.state.data.email && <span>{this.state.data.email}</span>}
                                                {!this.state.data.email && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Mobile:</td>
                                            <td>
                                                {this.state.data.mobile && <span>{this.state.data.mobile}</span>}
                                                {!this.state.data.mobile && <i>Not specified</i>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Expiry:</td>
                                            <td>
                                                <Moment format="ddd DD MMM, H:mma">
                                                    {this.state.data.expiry}
                                                </Moment>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">Status:</td>
                                            <td>
                                                {this.state.data.status === "accepted" && <Badge color="success">Acepted</Badge>}
                                                {this.state.data.status === "declined" && <Badge color="secondary">Declined</Badge>}
                                                {this.state.data.status === "pending" && <Badge color="primary">Active</Badge>}
                                                {this.state.data.status === "invalid" && <Badge color="secondary">Cancelled</Badge>}
                                                {this.state.data.status === "expired" && <Badge color="secondary">Expired</Badge>}
                                            </td>
                                        </tr>
                                    </table>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardBody>
                                    <h6>User which accepted</h6>
                                    {!this.state.data.user && <h6><i>Invite not yet accepted</i></h6>}
                                    {this.state.data.user &&
                                        <div>
                                            <table>
                                                <tr>
                                                    <td className="pr-2">Name:</td>
                                                    <td>
                                                        {this.state.data.user.name && <span>{this.state.data.user.name} {this.state.data.user.surname}</span>}
                                                        {!this.state.data.user.name && <i>Not specified</i>}</td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Email:</td>
                                                    <td>
                                                        {this.state.data.user.email && <span>{this.state.data.user.email}</span>}
                                                        {!this.state.data.user.email && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Mobile:</td>
                                                    <td>
                                                        {this.state.data.user.mobile && <span>{this.state.data.user.mobile}</span>}
                                                        {!this.state.data.user.mobile && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                            </table>
                                            <hr />
                                            <Link to={"/users/details/" + this.state.data.user.id} className="btn mr-2 btn-outline-dark mt-2">View profile</Link>
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }

                {!this.state.loading && this.state.data && this.state.data.status === "pending" &&
                    <div className="alert alert-danger">
                        {!this.state.deleteFail &&
                            <Row>
                                <Col>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Danger: </strong> You can cancel this invitation, doing so will permanently render it unusable</p></Col>
                                <Col md="2">
                                    <Button style={{ float: "right" }} to="/invitations" onClick={this.cancel} className="btn mr-2 mt-2 mb-2 btn-danger">Cancel invitation</Button>
                                </Col>
                            </Row>}

                        {this.state.deleteFail &&
                            <div>
                                <h5>Cancel failed</h5>
                                <p>This invitation has already been used and cannot be cancelled</p>
                            </div>}
                    </div>}
                </div>
            </Layout>
        );
    }

    async cancel() {
        await InvitationsClient.Cancel(this.props.match.params.id);
        await this.populateData(this.props.match.params.id);
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await await InvitationsClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }

}
