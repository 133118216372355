import React, { Component } from 'react';
import { FormDefinitionsClient } from '../api/FormDefinitionsClient';
import { FormDefinitionQueryParams } from '../api/FormDefinitionsClient';
import { SelectField } from '../SelectField';

export class FormSelector extends Component {

    defaultId() {
        return "formId";
    }

    constructor(props) {
        super(props);
        this.state = { init: false };
    }


    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {

        const {
            initialId,
            displayFormat,
            name
        } = this.props;

        var displayFormatter = (item) => {

            var display = displayFormat;

            if (!displayFormat)
                display = (item) => item.name;

            return display(item);

        }

        var query = new FormDefinitionQueryParams()
            .Sort("created", "desc");

        //TODO: SUPPORT SEARCH FOR PERFORMANCE AGAINST LARGE COLLECTIONS
        var data = (await FormDefinitionsClient.List(query.Paginate(0, 10000))).data;

        if (data) {
            this.setState({
                options: data.data.map(function (item) {
                    return {
                        value: item.id,
                        label: displayFormatter(item)
                    };
                })
            }, () => {
                if (initialId && !this.state.init) {
                    console.log("Initial value: " + initialId);
                    this.handleChange(name ? name : this.defaultId(), this.state.options.filter(i => i.value === initialId)[0]);
                }
                this.setState({
                    init: true
                });
            });
        }
    }

    handleChange = (name, value) => {
        const { onChange } = this.props;

        this.setState({ inputValue: value });

        console.log("Setting valuye");
        console.log(value);

        if (onChange)
            onChange(name, value);
    };

    render() {
        const {
            id,
            name,
            label,
            placeholder,
            value,
            isMulti,
            isDisabled,
            touched,
            error,
            onBlur,
            hideLabel,
        } = this.props;

        return (this.state.init && <SelectField
            id={id ? id : this.defaultId()}
            name={name ? name : this.defaultId()}
            label={label ? label : hideLabel ? null : "Form"}
            placeholder={placeholder ? placeholder : "Select form"}
            options={this.state.options}
            value={value}
            onChange={this.handleChange}
            onBlur={onBlur}
            touched={touched}
            isMulti={isMulti}
            error={error}
            isDisabled={isDisabled}
            isClearable={true}
            backspaceRemovesValue={true}
        />);
    }
}
