import { ListQueryParams, ApiResponse } from "../ApiClient";
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from "../Helper";

export class InviteQueryParams extends ListQueryParams {

    WithStatus(value: any) {
        this.WithParam("status", value);
        return this;
    }

}

export class InvitationsClient {

    static async List(params: InviteQueryParams) {

        var query = (await GetAPIDomain()) + "/invites" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invites/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async InviteUser(name: string, surname: string, email: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invites/user", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "surname": surname,
                "email": email
            })
        }));
    }

    static async AcceptInvite(code: string, name: string, surname: string, email: string, mobile: string, password: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invites/confirm/" + code, {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "email": email,
                "name": name,
                "surname": surname,
                "mobile": mobile,
                "password": password
            })
        }));
    }

    static async ValidateCode(code: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invites/validate", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "code": code
            })
        }));
    }

    static async Cancel(id: string) {

        var query = (await GetAPIDomain()) + "/invites/cancel/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post'
        }));
    }

    static async Decline(id: string) {

        var query = (await GetAPIDomain()) + "/invites/decline/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post'
        }));
    }

    static async Update(id: string, name: string, surname: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invites/" + id, {
            method: 'PATCH',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            },
            {
                "op": "replace",
                "path": "surname",
                "value": surname
            }])
        }));
    }

}