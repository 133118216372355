import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { RoleSelector } from '../../infrastructure/selectors/RoleSelector';
import { ApiClientsClient } from '../../infrastructure/api/ApiClientsClient';
import { Layout } from '../../shared/Layout';

export class ApiClientsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, screen: "initial"
        };

    }

    componentDidMount() {
        this.populateData();
    }


    renderSuccess() {
        return (<div><div class="row">
            <div class="col-md-10">
                <h5>API Client private key</h5>
                <p>For security you will not be able to access the private key for the API client again, please copy it to somewhere safe.</p>
                <div class="row">
                    <div class="col-md-12">
                        <div>
                            <label for="PrivateKey">Private Key</label>
                            <input disabled type="text" name="PrivateKey" class="form-control" id="PrivateKey" value={this.state.privateKey} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <hr />
            <div style={{ paddingRight: "10px" }}>
                <Link to={"/api-clients/details/" + this.state.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Next</Link>
            </div>
        </div>);
    }

    renderForm() {
        return (<Formik
            initialValues={{
                name: '',
                roleId: ''
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.name) {
                    setFieldError("name", "Please specify the client name");
                    return;
                }

                var response = await ApiClientsClient.Create(fields.name, fields.roleId ? [fields.roleId.value] : []);

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ screen: "client-secret", id: response.data.id, privateKey: response.data.privateKey, name: response.data.name });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="name">Name*</label>
                            <Field name="name" placeholder="E.g. App 01" type="text" className={'bgWhite form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                            <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <RoleSelector
                            initialId={values.roleId}
                            placeholder="Select role"
                            value={values.roleId}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            touched={touched.roleId}
                            error={errors.roleId} />

                        <ErrorMessage name="roleId" component="div" className="invalid-feedback" />
                    </Col>
                </Row>
                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "Create"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/api-clients/details/" + this.state.id} />);
        } else {
            return (
                <Layout>
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New API Client
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Register a new client</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/api-clients" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.screen === "initial" && this.renderForm()}
                            {this.state.screen === "client-secret" && this.renderSuccess()}
                        </CardBody>
                    </Card>
                    </div>
                </Layout>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            this.setState({
                init: true,
                loading: false
            });
        }
    }
}
