import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'react-moment';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { permissionContext } from '../../infrastructure/context/permissionContext';
import { FormTypesClient } from '../../infrastructure/api/FormTypesClient';
import { Layout } from '../../shared/Layout';

export class FormTypesDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false, restoreLoading: false,
            modal: false
        };

    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/form-types" />);
        } else {
            return (
                <Layout>
                <div>
                    {this.state.data && <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/form-types" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Form type details </span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}

                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        {this.state.data &&

                                            <permissionContext.Consumer>
                                                {({ permissions }) => {

                                                return (permissions.includes("form.definitions.modify") && <Link style={{ float: "right" }} to={"/form-types/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>);

                                                }}
                                            </permissionContext.Consumer>}

                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>}

                    {this.state.data && <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <h6>Details</h6>
                                    <hr />

                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Name:</td>
                                                <td>
                                                    {this.state.data.name && this.state.data.name}
                                                    {!this.state.data.name && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                           
                                            <tr>
                                                <td className="pr-2">Description:</td>
                                                <td>
                                                    {this.state.data.description && this.state.data.description}
                                                    {!this.state.data.description && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Created:</td>
                                                <td>
                                                    {this.state.data.created && <Moment utc local format="ddd DD MMM, h:mm a">{this.state.data.created}</Moment>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>}

                    </div>
                </Layout>
            );
        }
    }

    async populateData(id) {

        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await FormTypesClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {

                this.setState({ data: response.data, loading: false });
            }
        }
    }

}
