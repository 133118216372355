import React, { Component } from 'react';
import { Container, Spinner, Alert, Card, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import { ApiClient } from '../../infrastructure/ApiClient';
import { Layout } from '../../shared/Layout';

export class PasswordResetBegin extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true, success: false };
    }

    componentDidMount() {
        if (ApiClient.IsAuthenticated()) {
            ApiClient.LogOut();
        }
    }

    renderContent() {

        return (<Formik
            initialValues={{
                email: ''
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {
                if (!await ApiClient.BeginPasswordReset(fields.email)) {

                    setFieldError("email", "We can't find this email, do you normally use a different one?");
                } else {
                    this.setState({ success: true });
                }
            }}
            render={({ errors, status, touched, isSubmitting }) => (
                <Form>
                    <div className="form-group">
                        <Field name="email" placeholder="Email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting} style={{ width: "100%" }}>
                            {!isSubmitting && "RESET PASSWORD"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                    <div className="form-group text-right">
                        <Link className="text-right" to="/sign-in">Back to the sign in page</Link>
                    </div>
                </Form>
            )}
        />);
    }

    renderSuccessScreen() {

        return (<div><Alert color="success">
            <h5 style={{ color: "#299b85" }}>Success</h5>
            We have sent you an email with a link to reset your password.
                    </Alert>
            <Link className="btn mr-2 btn-outline-dark mt-2" to="/sign-in" style={{ width: "100%" }}>BACK TO SIGN IN</Link></div>);
    }

    render() {

        return (
            <Layout>
            <Card style={{ maxWidth: "600px", width: "100%" }}>
                <CardBody>
                    <div>
                        <Container>
                            <h3 className="mb-4">Forgotten password</h3>
                            <hr />
                            {!this.state.success && this.renderContent()}
                            {this.state.success && this.renderSuccessScreen()}
                        </Container>
                    </div>

                    </CardBody>
                </Card></Layout>
        );

    }
}