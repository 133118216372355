import { ListQueryParams, ApiResponse } from "../ApiClient";
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from "../Helper";

export class FormVersionQueryParams extends ListQueryParams {

    WithFormId(value: any) {
        this.WithParam("formDefinitionId", value);
        return this;
    }

}

export class FormVersionsClient {

    static async List(params: FormVersionQueryParams) {

        var query = (await GetAPIDomain()) + "/form-versions" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/form-versions/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Update(id: string, name: string, note: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/form-versions/" + id, {
            method: 'PATCH',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            },
            {
                "op": "replace",
                "path": "note",
                "value": note
            }])
        }));
    }


    static async Publish(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/form-versions/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([
                {
                    "op": "replace",
                    "path": "isPublished",
                    "value": true
                }])
        }));
    }

    static async Clone(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/form-versions/"+id+"/clone", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async Star(id: string, isStarred: Boolean) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/form-versions/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([
                {
                    "op": "replace",
                    "path": "isStarred",
                    "value": isStarred
                }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/form-versions/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));

    }

}